const GAME_TIME = 45;
const MAX_GIVEN_SCORE = 200;

window['GAME_TIME'] = GAME_TIME;
window['MAX_GIVEN_SCORE'] = MAX_GIVEN_SCORE;

import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './utils'

//  Bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

// Font Awesome 4.7
import 'font-awesome/css/font-awesome.css'

// Animate.css
import "animate.css"

// jQuery
window.$ = window.jQuery = require('jquery')
window.jQuery = window.$
jQuery = window.$

import imageMapResize from 'image-map-resizer'
window.$.imageMapResize = imageMapResize;

window['countdown_initial'] = process.env.VUE_APP_COUNTDOWN_INITIAL;
window['game_id'] = process.env.VUE_APP_GAME_ID;

window.vueapp = createApp(App).use(store).use(router).mount('#app')
import utils from './utils'

//router global variable.
window['router'] = router;
window['game_time'] = new Date().getTime() + (60000*process.env.VUE_APP_COUNTDOWN_MINUTES);

function HandleGame (){
    if(localStorage['store'+process.env.VUE_APP_GAME_ID] != undefined)
        vueapp.$store.commit('InitialiseStore', JSON.parse(localStorage['store'+process.env.VUE_APP_GAME_ID]));

    vueapp.$store.commit("SetEnvironment", process.env.NODE_ENV);

    if(vueapp.$store.state.game_start == null){
        router.push({name: "start"});
    }else{
        // Calculate the current time and the time the game started
        const currentTime = new Date().getTime();
        const gameStartTime = vueapp.$store.state.game_start;
         
        // Calculate the difference in milliseconds
        const timeDifference = currentTime - gameStartTime;
 
        // Calculate the duration in milliseconds from the provided number of minutes
        const countdownDuration = 60000 * process.env.VUE_APP_COUNTDOWN_MINUTES;
 
        // Check if more time than the specified duration has passed
        if (timeDifference > countdownDuration) {
            router.push({name: "end"});
        } else {
            if(vueapp.$store.state.last_url)
            {
                if(utils.RouteExists(vueapp.$store.state.last_url))
                    router.push({ name: vueapp.$store.state.last_url });
                else 
                    router.push({name: "start"});
            }
            else
                router.push({name: "start"});
        }
    }
}

window.utils = utils;
window['assets'] = [];

HandleGame();
