<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <Order :activity="activity"></Order>
        <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene4'});">Volver</button>
    </div>
</template>

<script>
import Order from './../activity/types/Order.vue'
import utils from '../../../utils'
export default {
    components: {
        Order
    },
    data(){
        return {
            activity:
            {
                id : "activity4_4",
                scene : "scene4",
                title : "Principios y finales",
                intro_text : "Cada vez vamos teniendo más datos sobre el episodio de hiperglucemia de Maria, pero antes de seguir avanzando, repasemos algunas características de los diferentes tipos de diabetes" ,
                question : "Ordena las siguientes frases",
                options : [
                    { id: 1, name: 'La insulina para un rápido control de la hiperglucemia', cssclass: 'opcion1', val: 1 }, 
                    { id: 2, name: 'Metformina', cssclass: 'opcion2', val: 2 },
                    { id: 3, name: 'Luna de miel', cssclass: 'opcion3', val: 3 }, 
                    { id: 4, name: 'Modificaciones dietéticas y ejercicio, si no es suficiente la insulina o metformina (menos usada en nuestro entorno)', cssclass: 'opcion4', val: 4 }, 
                    { id: 5, name: 'Insulina NPH, basal o fármacos no insulínicos como metformina, glinidas o iDPP4', cssclass: 'opcion5', val: 5 },
                ],
                correct_answer: [
                    5, 1, 4, 2, 3
                ],
                format: "list",
                titles: [
                    {
                        id: 1,
                        content: "La hiperglucemia esteroidea podemos tratarla con"
                    },
                    {
                        id: 2,
                        content: "El tratamiento de la diabetes tipo LADA es"
                    },
                    {
                        id: 3,
                        content: "Habitualmente el tratamiento de la diabetes gestacional es"
                    },
                    {
                        id: 4,
                        content: "El fármaco de primera línea en Diabetes tipo dos es"
                    },
                    {
                        id: 5,
                        content: "El periodo en el que no es necesario tratamiento insulínico en una diabetes"
                    }
                ],
                score: 4,
                clue_text : "Esta actividad puede ser útil para la resolución del caso",
                incorrect_msg : "La respuesta es incorrecta",
                feedback : "activity4_4_feedback"
            }
        };
    },
    updated: function(){
        this.$store.commit("SetNavBar", 'red');
    },
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity4_4');
    }
}
</script>