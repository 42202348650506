<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="activity-container  w-100 h-100">
                <div class="row w-100 h-100 m-0 d-flex justify-content-center align-items-start">
                    <div class="col-md-6 feedback1-5-text">
                        <div class="m-5 p-3 text-oswald">
                            <p>María es una paciente con antecedentes familiares de diabetes y con antecedentes personales de diabetes gestacional y de prediabetes tras el último embarazo.</p>
                            <p>Tras el estrés provocado por la infección del virus COVID-19 y el tratamiento con dosis elevadas de corticoides presenta un episodio de hiperglucemia aguda, con presencia de síntomas cardinales, por lo que precisa tratamiento inmediato con insulina, con una buena respuesta.</p>
                            <p>Por todo ello deberíamos pensar que tiene una diabetes tipo 2.</p>
                        </div>
                    </div>    
                    <div class="col-md-6 d-flex justify-content-center align-items-center">
                        <img id="feedback-image" src="@/assets/esc_5_act_3.png" alt="feedback-image">
                    </div> 
                </div>
            </div>
        </div>
        <button class="position-absolute btn btn-danger bottom-0 start-0 rounded m-5 text-oswald" onclick="router.push({name:'scene5'});">Volver</button>
    </div>
</template>

<script>
import utils from '../../../utils'
export default {
    name: 'Feedback5_3',
    mounted(){
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity5_3_feedback');
        // TODO: End
    },
}
</script>

<style scoped>
#feedback-image{
    max-width: 100%;
    max-height: 100%;
}
</style>