<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <Checks :activity="activity"></Checks>
        <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene3'});">Volver</button>
    </div>
</template>

<script>
import Checks from './../activity/types/Checks.vue'
import utils from '../../../utils'
export default {
    components: {
        Checks
    },
    data(){
        return {
            activity:
            {
                id : "activity3_4",
                scene : "scene3",
                title : "Antes de ir a casa",
                intro_text : "La consulta con enfermería de Maria está llegando a su final" ,
                question : "Tras finalizar la educación en insulinización en la que la paciente ha adquirido los conceptos básicos de manejo de insulina ¿Qué debería hacer ahora la enfermera?",
                options : [
                    {
                        text: 'Administrar nueva dosis de insulina glargina',
                        value: 0,
                    },
                    {
                        text: 'Recomendar realizar controles de glucemia capilar en ayunas',
                        value: 1,
                    },
                    {
                        text: 'Recomendar hacer un perfil glucémico de 6 puntos',
                        value: 2,
                    },
                    {
                        text: 'Enseñar a ajustar la dosis de insulina en función de la glucemia en ayunas, en coordinación con el médico',
                        value: 3,
                    },
                    {
                        text: 'Entregar glucómetro y tiras',
                        value: 4,
                    },
                    {
                        text: 'Entregar esfingomanómetro automático para control presión arterial',
                        value: 5,
                    },
                    {
                        text: 'Citar telefónicamente en 2 días para ver evolución y aclarar dudas que hayan podido surgir',
                        value: 6
                    }
                ],
                correct_answer: [
                    0, 1, 2, 3, 4, 6
                ],
                score: 10,
                clue_text : "Solo hay una respuesta incorrecta",
                incorrect_msg : "La respuesta es incorrecta",
                feedback : "activity3_4_feedback"
            }
        };
    },
    updated: function(){
        this.$store.commit("SetNavBar", 'red');
    },
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity3_4');
    }
}
</script>