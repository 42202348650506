<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <Radio :activity="activity"></Radio>
        <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene5'});">Volver</button>
    </div>
</template>

<script>
import Radio from './../activity/types/Radio.vue'
import utils from '../../../utils'
export default {
    components: {
        Radio
    },
    data(){
        return {
            activity:
            {
                id : "activity5_3",
                scene : "scene5",
                title : "Descubramos que le pasa a María",
                intro_text : "El caso está llegando a su final. Conocemos sus antecedentes familiares, personales, la evolución de los días posteriores al diagnóstico, los resultados de diversas pruebas complementarias...",
                question : "Teniendo en cuenta los antecedentes de la paciente y el resultado de la analítica ¿qué podemos concluir?",
                options : [
                    {
                        id: 0,
                        name: 'respuesta1',
                        text: 'Tiene una diabetes tipo 1',
                        value: 0,
                    },
                    {
                        id: 1,
                        name: 'respuesta2',
                        text: 'Tiene una diabetes tipo LADA',
                        value: 1,
                    },
                    {
                        id: 2,
                        name: 'respuesta3',
                        text: 'Tiene una diabetes corticoidea',
                        value: 2,
                    },
                    {
                        id: 3,
                        name: 'respuesta4',
                        text: 'Tiene una diabetes tipo 2 que ha debutado tras tratamiento corticoideo',
                        value: 3,
                    }
                ],
                score: 8,
                correct_answer: 3,
                clue_text : "Sólo una es correcta",
                incorrect_msg : "La respuesta es incorrecta",
                feedback : "activity5_3_feedback"
            }
        };
    },
    updated: function(){
        this.$store.commit("SetNavBar", 'red');
    },
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity5_3');
    }
}
</script>