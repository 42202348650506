<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <Radio :activity="activity"></Radio>
        <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene2'});">Volver</button>
    </div>
</template>

<script>
import Radio from './../activity/types/Radio.vue'
import utils from '../../../utils'
export default {
    components: {
        Radio
    },
    data(){
        return {
            activity:
            {
                id : "activity2_4",
                scene : "scene2",
                title : "Manejo insulina",
                intro_text : "La consulta de hoy casi ha llegado a su final, pero antes de despedirnos debemos revisar el tratamiento",
                question : "Su glucemia capilar actual es de 287 mg/dl y el día anterior le administraron 20 unidades de insulina glargina ¿Qué dosis le administrarías hoy?",
                options : [
                    {
                        id: 0,
                        name: 'respuesta1',
                        text: 'Nuevamente 20 unidades y comprobar la glucemia al día siguiente en la consulta de enfermería',
                        value: 0,
                    },
                    {
                        id: 1,
                        name: 'respuesta2',
                        text: 'Cambiaría por una pauta bolo-basal',
                        value: 1,
                    },
                    {
                        id: 2,
                        name: 'respuesta3',
                        text: 'Le administraría 10 unidades de insulina rápida ya que su control es muy malo',
                        value: 2,
                    },
                    {
                        id: 3,
                        name: 'respuesta4',
                        text: '24 unidades y al día siguiente revisaremos la glucemia en la consulta de la enfermera',
                        value: 3,
                    }
                ],
                score: 5,
                correct_answer: 3,
                clue_text : "Si dudas, pide el comodín del público. Solo una es correcta y no es la tercera",
                incorrect_msg : "La respuesta es incorrecta",
                feedback : "activity2_4_feedback"
            }
        };
    },
    updated: function(){
        this.$store.commit("SetNavBar", 'red');
    },
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity2_4');
    }
}
</script>