<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="activity-container  w-100 h-100">
                <div class="row w-100 h-100 m-0 d-flex justify-content-center align-items-start">
                    <div class="col-md-6 feedback1-5-text">
                        <div class="m-5 p-3 text-oswald">
                            <p class="text-center">Conserva estas letras para el reto final</p>
                            <h2 class="text-center feedback-big-text">T E R</h2>
                            <p><b>Vamos por muy buen camino, la glucemia se va normalizando y Maria se encuentra cada vez mejor. En la próxima consulta llegaremos al final</b></p>
                        </div>
                    </div>    
                    <div class="col-md-6 d-flex justify-content-center align-items-center">
                        <img id="feedback-image" src="@/assets/esc_4_act_4-min.png" alt="feedback-image">
                    </div> 
                </div>
            </div>
        </div>
        <button class="position-absolute btn btn-danger bottom-0 start-0 rounded m-5 text-oswald" onclick="router.push({name:'scene4'});">Volver</button>
    </div>
</template>

<script>
import utils from '../../../utils'
export default {
    name: 'Feedback4_4',
    mounted(){
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity4_4_feedback');
        this.$store.commit("SetSceneStatus", {scene: 'scene5',status: false});
    },
}
</script>

<style scoped>
#feedback-image{
    max-width: 100%;
    max-height: 100%;
}
</style>