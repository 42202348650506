<template>
    <div :class="'w-100 row m-0 p-2 ' +(($store.state.navbar == 'red') ? 'nav-red' : 'nav-white')" id="navigationbar">        
        <div class="d-flex d-md-none row">
            <div class="user-info col-sm-5">
                <div class="h-100 d-flex justify-content-center align-items-center w-100 py-1 py-md-0">
                    <img v-if="$store.state.navbar == 'white'" class="logo ms-3" src="@/assets/logo_positivo.png"/>
                    <img v-else class="logo ms-3" src="@/assets/logo_negativo.png">
                    <button v-if="$store.state.game_start != null" :class="'btn btn-sm rounded mb-0 ml-3 text-oswald ms-2 ' + (GetButtonColor())"  data-bs-toggle="modal" data-bs-target="#modal-center"><i class="fa fa-repeat" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="clock col-sm-2 d-flex justify-content-center align-items-center py-1 py-md-0">
                <div v-if="$store.state.timer" class="row no-gutters clock-container">
                    <div class="align-middle time-text" >
                        <FlipDown :type="2" :endDate="$store.state.timer" @timeUp="OnFinished"/>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 d-flex justify-content-center align-items-center py-1 py-md-0">
                <button v-if="!['map', 'start', 'countdown', 'end'].includes($route.name)" :class="'btn btn-sm rounded mb-0 ml-3 text-oswald me-2 ' + (GetButtonColor())" @click="GoBack">Mapa <i class="fa fa-map" aria-hidden="true"></i></button>
                <button type="button" :class="'btn btn-sm rounded mb-0 ml-3 text-oswald me-2 ' + (GetButtonColor())" id="fsbtn" ref="fsbtn" @click="ToggleFullScreen">
                    <i v-if="is_fullscreen == false" class="fa fa-expand" aria-hidden="true"></i>                    
                    <i v-if="is_fullscreen == true" class="fa fa-compress" aria-hidden="true"></i>
                </button>
                <button :class="'btn btn-sm text-oswald ml-3 ' + (GetButtonColor())" data-bs-toggle="modal" data-bs-target="#modal-help">
                    <i class="fa fa-question" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <div class="d-none d-md-flex row">
            <div class="user-info col-5">
                <div class="h-100 d-flex justify-content-center align-items-center w-100 py-1 py-md-0">
                    <img v-if="$store.state.navbar == 'white'" class="logo ms-3" src="@/assets/logo_positivo.png"/>
                    <img v-else class="logo ms-3" src="@/assets/logo_negativo.png">
                    <button v-if="$store.state.game_start != null" :class="'btn rounded mb-0 ml-3 text-oswald ms-2 ' + (GetButtonColor())"  data-bs-toggle="modal" data-bs-target="#modal-center"><i class="fa fa-repeat" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="clock col-2 d-flex justify-content-center align-items-center py-1 py-md-0">
                <div v-if="$store.state.timer" class="row no-gutters clock-container">
                    <div class="align-middle time-text" >
                        <FlipDown :type="2" :endDate="$store.state.timer" @timeUp="OnFinished"/>
                    </div>
                </div>
            </div>
            <div class="col-5 d-flex justify-content-center align-items-center py-1 py-md-0">
                <button v-if="!['map', 'start', 'countdown', 'end'].includes($route.name)" :class="'btn rounded mb-0 ml-3 text-oswald me-2 ' + (GetButtonColor())" @click="GoBack">Mapa <i class="fa fa-map" aria-hidden="true"></i></button>
                <button type="button" :class="'btn rounded mb-0 ml-3 text-oswald me-2 ' + (GetButtonColor())" id="fsbtn" ref="fsbtn" @click="ToggleFullScreen">
                    <i v-if="is_fullscreen == false" class="fa fa-expand" aria-hidden="true"></i>                    
                    <i v-if="is_fullscreen == true" class="fa fa-compress" aria-hidden="true"></i>
                </button>
                <button :class="'btn text-oswald ml-3 ' + (GetButtonColor())" data-bs-toggle="modal" data-bs-target="#modal-help">
                    <i class="fa fa-question" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import timer from '@/assets/timer.svg';
    import FlipDown from 'vue-flip-down';
    import utils from '../utils'
    import Help from '../views/Help'

    //popper
    export default {
        name: 'NavBar',
        components: {
            timer, FlipDown, Help
        },
        props:{
            leftTime: null
        },
        data: function(){
            return {
                image: '@/assets/logo.png',
                username: "",
                showuserdata: false,
                showmenu: false,
                //Timer (countdown game)
                time: '',
                user_avatar_name: '',
                newAvatar: '',
                is_fullscreen: false
            };
        },
        methods: {
            GetButtonColor()
            {
                return 'btn-'+((this.$store.state.navbar == 'white') ? 'red' : 'white');
            },
            OnFinished: function(){
                router.push({name:"end"})
            },
            GoBack(){
                if(typeof vueapp.$store.state.back_url === "string")
                    router.push({name: vueapp.$store.state.back_url});
                else
                    router.push({name:"map"});
            },
            ToggleFullScreen(){
                var status = utils.ToggleFullScreen();
                this.is_fullscreen = (status == true) ? false : true;
                $('#fsbtn').addClass('no-after');
            }
        }
    }
</script>

<style scoped>

</style>
