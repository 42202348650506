<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <Checks :activity="activity"></Checks>
        <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene4'});">Volver</button>
    </div>
</template>

<script>
import Checks from './../activity/types/Checks.vue'
import utils from '../../../utils'
export default {
    components: {
        Checks
    },
    data(){
        return {
            activity:
            {
                id : "activity4_3",
                scene : "scene4",
                title : "¿Qué deberíamos hacer?",
                intro_text : "Tras conocer las glucemias de los últimos días, debemos recordarle varios aspectos" ,
                question : "¿Qué le debería de indicar la enfermera?",
                options : [
                    {
                        text: 'Decirle que las glucemias antes de las comidas están elevadas',
                        value: 0,
                    },
                    {
                        text: 'Recordarle como debe de ajustar la insulina, aumentando la dosis en 2 unidades cada 3 días hasta que la glucemia en ayunas sea menor de 130 mg/dl',
                        value: 1,
                    },
                    {
                        text: 'Recordarle que debe seguir midiendo las glucemias en ayunas todos los días',
                        value: 2,
                    },
                    {
                        text: 'Repasar la técnica de manejo de insulina para comprobar que lo hace bien',
                        value: 3,
                    },
                    {
                        text: 'Recordarle que dentro de 3 días tiene cita en la consulta del médico para ver el resultado de la analítica y la evolución durante estos días',
                        value: 4,
                    },
                    {
                        text: 'Indicarle que debe de hacer un perfil glucémico todos los días',
                        value: 5,
                    }
                ],
                correct_answer: [
                    0, 1, 2, 3, 4
                ],
                score: 25,
                clue_text : "Hay una respuesta falsa",
                incorrect_msg : "La respuesta es incorrecta",
                feedback : "activity4_3_feedback"
            }
        };
    },
    updated: function(){
        this.$store.commit("SetNavBar", 'red');
    },
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity4_3');
    }
}
</script>