<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <div class="w-100">
            <div class="row m-0">
                <div class="col-6 text-oswald">
                    <ul class="mt-5">
                        <li>Tensión arterial: 110/65 mmHg</li>
                        <li>Glucemia capilar: 350 mg/dL</li>
                        <li>Saturación de oxígeno aire ambiente: 98%</li>
                        <li>Peso: 72,5 Kg</li>
                        <li>Talla: 167 cm</li>
                        <li>IMC: 26 Kg/m<sub>2</sub></li>
                        <li>Perímetro abdominal: 94 cm</li>
                        <li>EKG: Rítmico a 110 latidos por minutos. aQRS 60º. No alteraciones de la repolarización</li>
                    </ul>
                </div>
                <div class="col-6 text-oswald">
                    <ul class="mt-5">
                        <li>Nueva PCR: No es necesaria, 24 horas antes prueba no detectable</li>
                        <li>Glucemia capilar: 350 mg/dL</li>
                        <li>Tira de orina: pH 7,3; leucocitos +, proteínas +, hematíes +, glucosa ++, cuerpos cetónicos +.</li>
                        <li>Cetonas capilares: -</li>
                        <li>Ecografía urgente AP: sin hallazgos de interés.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row m-0 d-flex justify-content-center align-items-center">
            <div class="col-sm-12 col-md-10 col-lg-6" id="gallery">
                <a :href="img" 
                    data-pswp-width="2500" 
                    data-pswp-height="962" 
                    target="_blank" class="w-100 h-100 d-flex justify-content-center align-items-center p-3">
                    <img id="feedback-image" src="@/assets/esc_1_act_3.png" alt="esc_1_act_3">
                </a>
            </div>
        </div>
        <button class="position-absolute btn btn-danger bottom-0 end-0 rounded m-5 text-oswald" onclick="router.push({name:'scene1'});">Volver</button>
    </div>
</template>

<script>
import utils from '../../../utils'
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
export default {
    name: 'Feedback1_3',
    data(){
        return {
            img : require('@/assets/esc_1_act_3.png')
        };
    },
    mounted(){
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity1_3_feedback');
        const lightbox = new PhotoSwipeLightbox({
            gallery: '#gallery', 
            children: 'a',
            pswpModule: () => import('photoswipe')
        });
        lightbox.init();
    },
}
</script>

<style scoped>
#feedback-image
{
    max-width: 100%;
    max-height: 100%;
}
</style>