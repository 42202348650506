<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="activity-container  w-100 h-100">
                <div class="row w-100 h-100 m-0 d-flex justify-content-center align-items-start">
                    <div class="col-md-6 feedback1-5-text">
                        <p class="m-5 p-3 text-oswald">Todos los contenidos deben de ser trabajados en esta consulta de conceptos básicos de manejo de insulina, excepto el cuidado de los pies o un registro de alimentación de 24 horas que ya se harán en un momento posterior</p>
                    </div>    
                    <div class="col-md-6 d-flex justify-content-center align-items-center">
                        <img id="feedback-image" src="@/assets/esc_3_act_3-min.png" alt="feedback-image">
                    </div> 
                </div>
            </div>
        </div>
        <button class="position-absolute btn btn-danger bottom-0 start-0 rounded m-5 text-oswald" onclick="router.push({name:'scene3'});">Volver</button>
    </div>
</template>

<script>
import utils from '../../../utils'
export default {
    name: 'Feedback3_3',
    mounted(){
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity3_3_feedback');
    },
}
</script>

<style scoped>
#feedback-image{
    max-width: 100%;
    max-height: 100%;
}
</style>