<template>
    <div>
        <div class="row h-100 h-100 overflow-auto m-0" id="scene" ref="scene">
            <div class="col-md-4">
                <h3 class="title-scene ms-5 mt-5"><button class="btn text-primary info-btn d-md-none" @click="ToggleDescription"><i class="fa fa-info-circle" aria-hidden="true"></i></button> Consulta enfermería Atención Primaria</h3>
                <p class="description-scene d-none d-md-block">Es el momento de recibir educación básica sobre el manejo de insulina, por ello hoy la consulta es con enfermería</p>
            </div>
            <div class="col-md-8 h-100">
                <div id="scene-image" class="w-100 h-100 d-flex justify-content-center align-items-center" ref="mapContainer"> 
                    <Map class="w-100 h-100"></Map>                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { toHandlers } from 'vue';
import utils from '../../../utils'
    import Map from './Map.vue'
    export default {
        name: 'Scene1',
        components: {
            Map
        },
        data() {
            return {
                debugBoxes: [],
                last_activity: null,
                activities: [
                    "activity3_1",
                    "activity3_2",
                    "activity3_3",
                    "activity3_4"
                ]
            };
        },
        mounted()
        {
            utils.AdjustGameScreen();
            this.$store.commit("SetNavBar", 'red');
            this.$store.commit("SetLastURL", 'scene3');
            this.AddRipples();
            // Listen for window resize event
            window.addEventListener('resize', this.HandleResize);
            this.$refs.scene.addEventListener('scroll', this.HandleResize);
        },
        beforeUnmount() {
            // Remove the resize event listener when the component is destroyed
            window.removeEventListener('resize', this.HandleResize);

            // Remove the click event listener for each scene activity
            let ripples = document.querySelectorAll('.ripple-activity')
                
            ripples.forEach((ripple) => {
                ripple.addEventListener("click", this.ClickActivity)
            });
        },
        methods:
        {
            ToggleDescription()
            {
                $('.description-scene').toggleClass("d-none d-md-block");
                this.HandleResize();
            },
            ClickActivity(evt) 
            {
                if(this.last_activity == evt.target.dataset.id)
                {
                    let unlocked = true;
                    this.activities.forEach(act => {
                        if (act !== this.last_activity && !this.GetActivityById(act) && unlocked == true) unlocked = false;
                    });

                    
                    if(unlocked)
                        this.$router.push({name: evt.target.dataset.id});
                    else
                        this.$router.push({name: 'locked'});
                }
                else
                    this.$router.push({name: evt.target.dataset.id});
            },
            HandleResize() 
            {
                // When the window is resized, adjust positions and sizes of existing debug boxes
                this.debugBoxes.forEach(box => {
                    var rectId = box.dataset.id;
                    var rect = document.getElementById(rectId);
                    var x = rect.getAttribute('x');
                    var y = rect.getAttribute('y');
                    var w = rect.getAttribute('width');
                    var h = rect.getAttribute('height');

                    var esc1 = document.getElementById('escenario_3');
                    var escRect = esc1.getBoundingClientRect();
                    var imageWidth = escRect.width;
                    var imageHeight = escRect.height;
                    var naturalWidth = rect.ownerSVGElement.viewBox.baseVal.width;
                    var naturalHeight = rect.ownerSVGElement.viewBox.baseVal.height;

                    var adjustedX = (x * imageWidth) / naturalWidth;
                    var adjustedY = (y * imageHeight) / naturalHeight;
                    var adjustedW = (w * imageWidth) / naturalWidth;
                    var adjustedH = (h * imageHeight) / naturalHeight;

                    box.style.top = escRect.top + adjustedY + 'px';
                    box.style.left = escRect.left + adjustedX + 'px';
                    box.style.width = adjustedW + 'px';
                    box.style.height = adjustedH + 'px';
                });
            },
            GetActivity(id)
            {
                let status = this.$store.state.scenes.scene3.activities['activity3_'+id];
                return (status == null) ? false : status;
            },
            GetActivityById(id)
            {
                let status = this.$store.state.scenes.scene3.activities[id];
                return (status == null) ? false : status;
            },
            AddRipples() 
            {
                // Clear existing debug boxes
                this.debugBoxes.forEach(box => box.remove());
                this.debugBoxes = [];
                var mapRects = document.querySelectorAll('rect');
                var svgEl = document.querySelectorAll('svg');
                var esc1 = document.getElementById('escenario_3');
                
                var escRect = esc1.getBoundingClientRect();
                var imageWidth = escRect.width;
                var imageHeight = escRect.height;

                var naturalWidth = svgEl[0].viewBox.baseVal.width;
                var naturalHeight = svgEl[0].viewBox.baseVal.height;            

                mapRects.forEach((rect, key) => {
                    
                    var x = rect.getAttribute('x');
                    var y = rect.getAttribute('y');
                    var w = rect.getAttribute('width');
                    var h = rect.getAttribute('height');
                    var id = rect.getAttribute('id');
                    
                    var adjustedX = (x * imageWidth) / naturalWidth;
                    var adjustedY = (y * imageHeight) / naturalHeight;
                    var adjustedW = (w * imageWidth) / naturalWidth;
                    var adjustedH = (h * imageHeight) / naturalHeight;

                    // Create a new debug box for each area
                    var div = document.createElement('div');
                    div.className = 'position-absolute d-flex justify-content-center align-items-center ripple-activity';
                    div.style.top = escRect.top + adjustedY + 'px';
                    div.style.left = escRect.left + adjustedX + 'px';
                    div.style.width = adjustedW + 'px';
                    div.style.height = adjustedH + 'px';
                    div.dataset.id = id;

                    // Append the debug box to the image container
                    this.$refs.scene.appendChild(div);
                    //div.innerText = rect.id;
                    // Store reference to the created debug box
                    this.debugBoxes.push(div);

                    var divRipple = document.createElement('div');
                    divRipple.className = 'position-absolute';
                    if(!this.GetActivity(key+1))
                        divRipple.classList.add((key == 0) ? "main-ripple" : "ripple");
                    divRipple.dataset.id = id;

                    // Append the debug box to the image container
                    div.appendChild(divRipple);

                    var divSmallRipple = document.createElement('div');
                    divSmallRipple.className = 'position-absolute';
                    if(!this.GetActivity(key+1))
                        divSmallRipple.classList.add((key == 0) ? "main-small-ripple" : "small-ripple");
                    divSmallRipple.dataset.id = id;

                    // Append the debug box to the image container
                    div.appendChild(divSmallRipple);

                    this.last_activity = id;
                });

                let ripples = document.querySelectorAll('.ripple-activity')                
                ripples.forEach((ripple) => {
                    ripple.addEventListener("click", this.ClickActivity)
                });
            }
        }
    }
</script>

<style>
</style>
