<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <Checks :activity="activity"></Checks>
        <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene2'});">Volver</button>
    </div>
</template>

<script>
import Checks from './../activity/types/Checks.vue'
import utils from '../../../utils'
export default {
    components: {
        Checks
    },
    data(){
        return {
            activity:
            {
                id : "activity2_3",
                scene : "scene2",
                title : "Ampliación anamnesis",
                intro_text : "Antes de finalizar la consulta, necesitamos conocer más datos sobre Maria." ,
                question : "¿Cuáles de los siguientes datos de la historia clínica sería conveniente revisar en estos momentos?",
                options : [
                    {
                        text: 'Antecedentes familiares de diabetes',
                        value: 0,
                    },
                    {
                        text: 'Antecedentes familiares de cáncer de mama',
                        value: 1,
                    },
                    {
                        text: 'Nº embarazos',
                        value: 2,
                    },
                    {
                        text: 'Antecedentes personales de diabetes gestacional',
                        value: 3,
                    },
                    {
                        text: 'Revisar la glucemia en analíticas previas',
                        value: 4,
                    },
                    {
                        text: 'Revisar tratamiento recibido en los días previos durante la infección por SARs-COV-2',
                        value: 5,
                    },
                    {
                        text: 'Edad menarquia',
                        value: 6
                    }
                ],
                correct_answer: [
                    0, 2, 3, 4, 5
                ],
                score: 30,
                clue_text : "Hay más de una respuesta correcta y la edad de menarquía no es necesaria",
                incorrect_msg : "La respuesta es incorrecta",
                feedback : "activity2_3_feedback"
            }
        };
    },
    updated: function(){
        this.$store.commit("SetNavBar", 'red');
    },
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity2_3');
    }
}
</script>