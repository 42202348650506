<template>
    <div class="row h-100 w-100 m-0 d-flex justify-content-center align-items-center position-relative">
        <img id="countdown-dor" class="animate__animated animate__zoomIn" src="@/assets/pantalla_inicio.png" alt="pantalla_inicio">
        <span id="countdown" class="w-100 h-100 d-flex justify-content-center align-items-center position-absolute">{{ $store.state.countdown }}</span>
    </div>
</template>

<script>
    import utils from '../utils'
    export default {
        name : 'CountDown',
        mounted : function(){
            this.$store.commit("SetNavBar", 'red');
            this.$store.commit("SetLastURL", 'countdown');
            utils.StartCountDown();
        }
    }
</script>

<style scoped>
    #countdown{
        font-size: 200px;
    }
</style>
