<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 2118 1921.8" style="enable-background:new 0 0 2118 1921.8;" xml:space="preserve">
        <g id="escenario_3">
            <image id="scene-bg" class="w-100 h-100" href="@/assets/scenes/caso_1_esc_3.png"></image>
        </g>
        <g id="escena_x5F_path">
            <path id="escenario_x5F_3_x5F_path" class="st0" d="M2116.7,609.9L1059.4,0.3L2,610.8l0.1,697.6l1057.4,610.5l1057.2-610.5V609.9z"/>
            <rect id="activity3_1" fill="rgb(0,0,0,0)" x="1694.6" y="924.4" class="st1 activity" width="311.2" height="291.3"/>
            <rect id="activity3_2" fill="rgb(0,0,0,0)" x="458.8" y="963.7" class="st1 activity" width="322.2" height="255.1"/>
            <rect id="activity3_3" fill="rgb(0,0,0,0)" x="1446.5" y="434.7" class="st1 activity" width="289.1" height="341.6"/>
            <rect id="activity3_4" data-last="activity3_3" fill="rgb(0,0,0,0)" x="1197.6" y="820.2" class="st1 activity" width="396.3" height="318.8"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Map'
    }
</script>

<style>
    .st0{opacity:0.15;fill:#FFFFFF;}
    .st1{fill:#FFFFFF;fill-opacity:0;}
</style>