import { createRouter, createWebHistory } from 'vue-router'

import Game from '../components/Game.vue'
import End from '../components/end/End.vue'

//Scenes
import Scene1 from '../components/scenes/scene1/Scene1.vue'
import Scene2 from '../components/scenes/scene2/Scene2.vue'
import Scene3 from '../components/scenes/scene3/Scene3.vue'
import Scene4 from '../components/scenes/scene4/Scene4.vue'
import Scene5 from '../components/scenes/scene5/Scene5.vue'

// Activities
import Activity1_1 from '../components/scenes/activities/Activity1_1.vue';
import Activity1_2 from '../components/scenes/activities/Activity1_2.vue';
import Activity1_3Checks from '../components/scenes/activities/Activity1_3Checks.vue';
import Activity1_3Feedback from '../components/scenes/feedbacks/FeedbackEsc1Act3.vue';
import Activity1_4 from '../components/scenes/activities/Activity1_4.vue';
import Activity1_5Radio from '../components/scenes/activities/Activity1_5Radio.vue';
import Activity1_5Feedback from '../components/scenes/feedbacks/FeedbackEsc1Act5.vue';

import Activity2_1 from '../components/scenes/activities/Activity2_1.vue';
import Activity2_2 from '../components/scenes/activities/Activity2_2.vue';
import Activity2_3Checks from '../components/scenes/activities/Activity2_3Checks.vue';
import Activity2_3Feedback from '../components/scenes/feedbacks/FeedbackEsc2Act3.vue';
import Activity2_4Radio from '../components/scenes/activities/Activity2_4Radio.vue';
import Activity2_4Feedback from '../components/scenes/feedbacks/FeedbackEsc2Act4.vue';

import Activity3_1 from '../components/scenes/activities/Activity3_1.vue';
import Activity3_2 from '../components/scenes/activities/Activity3_2.vue';
import Activity3_3Checks from '../components/scenes/activities/Activity3_3Checks.vue';
import Activity3_3Feedback from '../components/scenes/feedbacks/FeedbackEsc3Act3.vue';
import Activity3_4Checks from '../components/scenes/activities/Activity3_4Checks.vue';
import Activity3_4Feedback from '../components/scenes/feedbacks/FeedbackEsc3Act4.vue';

import Activity4_1 from '../components/scenes/activities/Activity4_1.vue';
import Activity4_2 from '../components/scenes/activities/Activity4_2.vue';
import Activity4_3Checks from '../components/scenes/activities/Activity4_3Checks.vue';
import Activity4_3Feedback from '../components/scenes/feedbacks/FeedbackEsc4Act3.vue';
import Activity4_4Order from '../components/scenes/activities/Activity4_4Order.vue';
import Activity4_4Feedback from '../components/scenes/feedbacks/FeedbackEsc4Act4.vue';

import Activity5_1 from '../components/scenes/activities/Activity5_1.vue';
import Activity5_2 from '../components/scenes/activities/Activity5_2.vue';
import Activity5_3Radio from '../components/scenes/activities/Activity5_3Radio.vue';
import Activity5_3Feedback from '../components/scenes/feedbacks/FeedbackEsc5Act3.vue';
import Activity5_4Text from '../components/scenes/activities/Activity5_4Text.vue';
import Activity5_4Feedback from '../components/scenes/feedbacks/FeedbackEsc5Act4.vue';

// StartGame
import Start from '../views/Start.vue'
import CountDown from '../views/CountDown.vue'
import Map from '../views/Map.vue'

//Locked activity
import Locked from '../views/Locked.vue'

// Test
import Test from '../views/Test.vue'

const game_id = '/game_'+process.env.VUE_APP_GAME_ID;
const routes = [
    {
        path: '/game_'+process.env.VUE_APP_GAME_ID,
        name: 'home',
        component: Game,
        meta: {
            isPublic: true,
        },
        children: [
            {
                path: 'start',
                name: 'start',
                meta: {
                    isPublic: true,
                },
                component: Start
            },
            {
                path: 'countdown',
                name: 'countdown',
                meta: {
                    isPublic: true,
                },
                component: CountDown
            },
            {
                path: 'map',
                name: 'map',
                component: Map,
                meta: {
                    isPublic: true,
                }
            },
            {
                path: 'locked',
                name: 'locked',
                component: Locked,
                meta: {
                    isPublic: true,
                },
                props: {
                    default: true,
                }
            },
            {
                path: 'test',
                name: 'test',
                component: Test,
                meta: {
                    isPublic: true,
                }
            },
            /*{
                path: 'activitytest',
                name: 'activitytest',
                component: TestActivityDialogue,
                meta: {
                    isPublic: true,
                }
            },*/
            {
                path: 'end',
                name: 'end',
                component: End
            },
            //Scenes
            {
                path: 'scene1',
                name: 'scene1',
                component: Scene1
            },
            {
                path: 'scene2',
                name: 'scene2',
                component: Scene2
            },
            {
                path: 'scene3',
                name: 'scene3',
                component: Scene3
            },
            {
                path: 'scene4',
                name: 'scene4',
                component: Scene4
            },
            {
                path: 'scene5',
                name: 'scene5',
                component: Scene5
            },
            //Games
            {
                path: 'scene1/act_01',
                name: 'activity1_1',
                props: true,
                component: Activity1_1,
            },
            {
                path: 'scene1/act_02',
                name: 'activity1_2',
                props: true,
                component: Activity1_2
            },
            {
                path: 'scene1/act_03',
                name: 'activity1_3',
                props: true,
                component: Activity1_3Checks,
            },
            {
                path: 'scene1/act_03/feedback',
                name: 'activity1_3_feedback',
                props: true,
                component: Activity1_3Feedback,
            },
            {
                path: 'scene1/act_04',
                name: 'activity1_4',
                props: true,
                component: Activity1_4,
            },
            {
                path: 'scene1/act_05',
                name: 'activity1_5',
                props: true,
                component: Activity1_5Radio,
            },
            {
                path: 'scene1/act_05/feedback',
                name: 'activity1_5_feedback',
                props: true,
                component: Activity1_5Feedback,
            },
            // Scene2
            {
                path: 'scene2/act_01',
                name: 'activity2_1',
                props: true,
                component: Activity2_1,
            },
            {
                path: 'scene2/act_02',
                name: 'activity2_2',
                props: true,
                component: Activity2_2,
            },
            {
                path: 'scene2/act_03',
                name: 'activity2_3',
                props: true,
                component: Activity2_3Checks,
            },
            {
                path: 'scene2/act_03/feedback',
                name: 'activity2_3_feedback',
                props: true,
                component: Activity2_3Feedback,
            },
            {
                path: 'scene2/act_04',
                name: 'activity2_4',
                props: true,
                component: Activity2_4Radio,
            },
            {
                path: 'scene2/act_04/feedback',
                name: 'activity2_4_feedback',
                props: true,
                component: Activity2_4Feedback,
            },
            //Scene3
            {
                path: 'scene3/act_01',
                name: 'activity3_1',
                props: true,
                component: Activity3_1,
            },
            {
                path: 'scene3/act_02',
                name: 'activity3_2',
                props: true,
                component: Activity3_2,
            },
            {
                path: 'scene3/act_03',
                name: 'activity3_3',
                props: true,
                component: Activity3_3Checks,
            },
            {
                path: 'scene3/act_03/feedback',
                name: 'activity3_3_feedback',
                props: true,
                component: Activity3_3Feedback,
            },
            {
                path: 'scene3/act_04',
                name: 'activity3_4',
                props: true,
                component: Activity3_4Checks,
            },
            {
                path: 'scene3/act_04/feedback',
                name: 'activity3_4_feedback',
                props: true,
                component: Activity3_4Feedback,
            },
            // Scene 4
            {
                path: 'scene4/act_01',
                name: 'activity4_1',
                props: true,
                component: Activity4_1
            },
            {
                path: 'scene4/act_02',
                name: 'activity4_2',
                props: true,
                component: Activity4_2
            },
            {
                path: 'scene4/act_03',
                name: 'activity4_3',
                props: true,
                component: Activity4_3Checks
            },
            {
                path: 'scene4/act_03/feedback',
                name: 'activity4_3_feedback',
                props: true,
                component: Activity4_3Feedback,
            },
            {
                path: 'scene4/act_04',
                name: 'activity4_4',
                props: true,
                component: Activity4_4Order
            },
            {
                path: 'scene4/act_04/feedback',
                name: 'activity4_4_feedback',
                props: true,
                component: Activity4_4Feedback,
            },
            //Scene 5
            {
                path: 'scene5/act_01',
                name: 'activity5_1',
                props: true,
                component: Activity5_1
            },
            {
                path: 'scene5/act_02',
                name: 'activity5_2',
                props: true,
                component: Activity5_2
            },
            {
                path: 'scene5/act_03',
                name: 'activity5_3',
                props: true,
                component: Activity5_3Radio
            },
            {
                path: 'scene5/act_03/feedback',
                name: 'activity5_3_feedback',
                props: true,
                component: Activity5_3Feedback,
            },
            {
                path: 'scene5/act_04',
                name: 'activity5_4',
                props: true,
                component: Activity5_4Text
            },
            {
                path: 'scene5/act_04/feedback',
                name: 'activity5_4_feedback',
                props: true,
                component: Activity5_4Feedback,
            },
            
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    next();
})

export default router