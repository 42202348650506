<template>
    <div class="end w-100 animate__animated animate__fadeIn p-2">
        <div v-if="$store.state.game_over" id="gameover-container" class="h-100 w-100 d-flex justify-content-center align-items-center flex-column">
            <img id="pantalla_perder" src="@/assets/pantalla_perder.png" class="h-50">        
            <h1 class="mt-4 text-rockwell text-center">Se ha terminado el tiempo</h1>
            <h3 class="mt-4 text-rockwell text-center">¡No has completado el juego!</h3>
            <h5 class="mt-4 text-rockwell text-center">Has conseguido un total de {{ $store.state.score }} puntos!</h5>
        </div>
        <div v-else class="w-100 h-100 d-flex justify-content-center align-items-center flex-column position-relative" id="game-end-true">
            <img class="images-end animate__heartBeat h-50" src="@/assets/pantalla_ganar.png" alt="pantalla_ganar">                    
            <h1 class="mt-4 text-rockwell text-center">¡Enhorabuena!</h1>
            <h3 class="mt-4 text-rockwell text-center">¡Has completado el caso!</h3>
            <h5 class="mt-4 text-rockwell text-center">Has conseguido un total de {{ $store.state.score }} puntos!</h5>
            <canvas id="confetti"></canvas>
        </div>
    </div>
</template>

<script>
import utils from '../../utils'
export default {
    name: 'End',
    mounted() {
        if(this.$store.state.game_over == false)
        {
            var audio = vueapp.$store.state.sounds["EndSound"];
            if(audio)
                audio.play();
            this.startConfettiAnimation();
        }
        else
        {
            var audio = vueapp.$store.state.sounds["GameOverSound"];
            if(audio)
                audio.play();
        }
        setTimeout(() => {                
            utils.AdjustGameScreen();
        }, 1000);
    },
    methods: {
        random(min, max) {
            return Math.random() * (max - min) + min;
        },
        createConfetti(x, y, size, color) {
            return {
                x: x,
                y: y,
                size: size,
                color: color,
                // Add velocity properties for falling effect
                velocityX: Math.random() * 2 - 1, // Random horizontal velocity
                velocityY: Math.random() * 5 + 1 // Random vertical velocity
            };
        },
        initConfetti() {
            const canvas = document.getElementById("confetti");
            const ctx = canvas.getContext("2d");
            const container = document.getElementById("game-end-true");

            // Set canvas width and height
            canvas.width = container.clientWidth;
            canvas.height = container.clientHeight;

            // Array to store confetti objects
            const confettiArray = [];

            for (let i = 0; i < 100; i++) {
                const x = this.random(0, canvas.width);
                const y = this.random(-canvas.height, 0);
                const size = this.random(5, 20);
                const color = `hsl(${this.random(0, 360)}, 100%, 50%)`; // Random color
                confettiArray.push(this.createConfetti(x, y, size, color));
            }

            return { canvas, ctx, confettiArray };
        },
        updateConfetti(confettiArray, canvas) {
            confettiArray.forEach((confetti) => {
                // Update confetti position
                confetti.x += confetti.velocityX;
                confetti.y += confetti.velocityY;

                // Apply gravity
                confetti.velocityY += 0.1;

                // Reset confetti position if it goes off the canvas
                if (confetti.y > canvas.height) {
                    confetti.x = this.random(0, canvas.width);
                    confetti.y = this.random(-canvas.height, 0);
                    confetti.velocityY = Math.random() * 5 + 1;
                }
            });
        },
        drawConfetti(ctx, canvas, confettiArray) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            confettiArray.forEach((confetti) => {
                ctx.beginPath();
                ctx.moveTo(confetti.x, confetti.y);
                ctx.lineTo(confetti.x + confetti.size, confetti.y);
                ctx.lineTo(confetti.x + confetti.size, confetti.y + confetti.size);
                ctx.lineTo(confetti.x, confetti.y + confetti.size);
                ctx.closePath();
                ctx.fillStyle = confetti.color;
                ctx.fill();
            });
        },
        animate() {
            const { ctx, confettiArray, canvas } = this.initConfetti();

            const animateFrame = () => {
                this.updateConfetti(confettiArray, canvas);
                this.drawConfetti(ctx, canvas, confettiArray);
                requestAnimationFrame(animateFrame);
            };

            animateFrame();
        },
        startConfettiAnimation() {
            // Start animation
            this.animate();
        },
    },
};
</script>

<style scoped>
.images-end {
    max-width: 100%;
    max-height: 100%;
}
#confetti {
    width: 100%;
    height: 100%;
    position: absolute;
}
</style>
