<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 2118 1921.8" style="enable-background:new 0 0 2118 1921.8;" xml:space="preserve">
        <g id="escenario_2">
            <image id="scene-bg" class="w-100 h-100" href="@/assets/scenes/caso_1_esc_2.png"></image>
        </g>
        <g id="escena_x5F_path">
            <path id="escenario_x5F_2_x5F_path" class="st0" d="M2118,610.9L1059,0.3L0,611.7l0.1,698.7l1059,611.4L2118,1310.3V610.9z"/>
            <rect id="activity2_1" x="1054.9" y="600.1" class="st1 activity" fill="rgba(0,0,0,0)" width="273.8" height="339.6"/>
            <rect id="activity2_2" x="780" y="946.8" class="st1 activity" fill="rgba(0,0,0,0)" width="322.2" height="255.1"/>
            <rect id="activity2_3" x="1352.2" y="553.2" class="st1 activity" fill="rgba(0,0,0,0)" width="323.7" height="309.2"/>
            <rect id="activity2_4" data-last="activity2_3" x="683.4" y="616.3" class="st1 activity" fill="rgba(0,0,0,0)" width="304.4" height="235.9"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Map'
    }
</script>

<style>
    .st0{opacity:0.15;fill:#FFFFFF;}
    .st1{fill:#FFFFFF;fill-opacity:0;}
</style>