<template>
    <div class="w-100 animate__animated animate__fadeIn activity-dialogue-container overflow-hidden">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center position-relative">
            <img id="activity-image" class="position-absolute start-0 bottom-0" src="@/assets/esc_4_act_2_1-min.png" alt="esc1act1" rel="preload">
            <img id="activity-image" class="position-absolute end-0 bottom-0 animate__animated animate__fadeInRight" src="@/assets/esc_4_act_2.png" alt="esc1act1" rel="preload">
            <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene4'});">Volver</button>
        </div>
    </div>
</template>

<script>
import utils from '../../../utils'
export default {
    name: "act_01",
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity4_2');
        this.$store.commit("SetActivityStatus", 
            {
                scene: 'scene4', 
                act: 'activity4_2', 
                status: true
            }
        );
        
        var audio = vueapp.$store.state.sounds["CorrectSound"];
        if(audio)
        {
            audio.pause();
            audio.currentTime = 0;
            audio.play();
        }
    }
}
</script>

<style scoped>
#activity-image
{
    max-width: 100%;
    max-height: 100%;
}
</style>