<template>
    <router-view></router-view>
</template>

<script>    
    export default {
        name: 'Game',
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
