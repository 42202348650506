<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <Radio :activity="activity"></Radio>
        <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene1'});">Volver</button>
    </div>
</template>

<script>
import Radio from './../activity/types/Radio.vue'
import utils from '../../../utils'
export default {
    components: {
        Radio
    },
    data(){
        return {
            activity:
            {
                id : "activity1_5",
                scene : "scene1",
                title : "Diagnóstico",
                intro_text : "Tras la anamnesis y la exploración, ¿qué sospechamos que le pasa a María?",
                question : "¿Cuál es la sospecha diagnóstica?",
                options : [
                    {
                        id: 0,
                        name: 'respuesta1',
                        text: 'Tiene una probable recidiva de infección por virus COVID-19',
                        value: 0,
                    },
                    {
                        id: 1,
                        name: 'respuesta2',
                        text: 'Presenta una gastroenteritis aguda',
                        value: 1,
                    },
                    {
                        id: 2,
                        name: 'respuesta3',
                        text: 'Tiene una descompensación hiperglucémica aguda',
                        value: 2,
                    },
                    {
                        id: 3,
                        name: 'respuesta4',
                        text: 'Probablemente presenta una tormenta de citocinas por virus del COVID-19',
                        value: 3,
                    }
                ],
                score: 6,
                correct_answer: 2,
                clue_text : "Recuerda que la PCR del día anterior es negativa y la saturación del ambiente del 98%",
                incorrect_msg : "La respuesta es incorrecta",
                feedback : "activity1_5_feedback"
            }
        };
    },
    updated: function(){
        this.$store.commit("SetNavBar", 'red');
    },
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity1_5');
    }
}
</script>