<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="activity-container  w-100 h-100">
                <div class="row w-100 h-100 m-0 d-flex justify-content-center align-items-start">
                    <div class="col-md-6 feedback1-5-text">
                        <ul class="m-5 p-3 text-oswald">
                            <li><b>Antecedentes familiares de diabetes:</b> Su padre tiene diabetes tipo 2 desde los 58 años en tratamiento con antidiabéticos no insulínicos</li>
                            <li><b>Nº embarazos:</b> 2. Tiene una hija de 8 y un hijo de 5 años. No datos de macrosomía fetal.</li>
                            <li><b>Antecedentes personales de diabetes gestacional:</b> En el último embarazo tuvo diabetes gestacional que se controló con cambios en el estilo de vida.</li>
                            <li><b>Revisar la glucemia en analíticas previas:</b> En los controles tras el último embarazo ha tenido valores de glucemia entre 104-116 mg/dl y una HbA1c entre 5,6-6,1%. Último valor de hace 18 meses.</li>
                            <li><b>Revisar tratamiento recibido en los días previos durante la infección por SARs-COV-2:</b> 6 mg/día de dexametasona durante 7 días.</li>
                        </ul>
                    </div>    
                    <div class="col-md-6 d-flex justify-content-center align-items-center">
                        <img id="feedback-image" src="@/assets/esc_2_act_3-min.png" alt="feedback-image">
                    </div> 
                </div>
            </div>
        </div>
        <button class="position-absolute btn btn-danger bottom-0 start-0 rounded m-5 text-oswald" onclick="router.push({name:'scene2'});">Volver</button>
    </div>
</template>

<script>
import utils from '../../../utils'
export default {
    name: 'Feedback2_3',
    mounted(){
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity2_3_feedback');
    },
}
</script>

<style scoped>
#feedback-image{
    max-width: 100%;
    max-height: 100%;
}
</style>