<template>
    <div class="w-100 animate__animated animate__fadeIn activity-dialogue-container">      
        <div class="row w-100 h-100">
            <div class="col-md-7 d-flex justify-content-center align-items-center">
                <img id="activity-image" src="@/assets/esc_1_act_2-min.png" alt="esc1act2" rel="preload">
            </div> 
            <div class="col-md-5">
                <ul class="m-5 p-3 text-oswald">
                    <li>Piel y faneras: mucosa oral reseca. Pliegue negativo.</li>
                    <li>Auscultación cardiaca: rítmico, no soplos a 100lpm.</li>
                    <li>Auscultación pulmonar: murmullo vesicular conservado, no soplos ni extratonos.</li>
                    <li>Abdomen: blando, depresible, no doloroso a la palpación. No defensa local. No se palpan masas ni megalias. Ruidos hidroaéreos presentes, no soplos. Blumberg y Murphy negativos.</li>
                </ul>
            </div>
            <button class="position-fixed w-auto bottom-0 end-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene1'});">Volver</button>
        </div>
    </div>
</template>

<script>
import utils from '../../../utils'
export default {
    name: "act_02",
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity1_2');
        this.$store.commit("SetActivityStatus", 
            {
                scene: 'scene1', 
                act: 'activity1_2', 
                status: true
            }
        );
        
        var audio = vueapp.$store.state.sounds["CorrectSound"];
        if(audio)
        {
            audio.pause();
            audio.currentTime = 0;
            audio.play();
        }
    }
}
</script>

<style scoped>
#activity-image
{
    max-width: 100%;
    max-height: 100%;
}
</style>