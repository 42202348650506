<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 2118 1921.8" style="enable-background:new 0 0 2118 1921.8;" xml:space="preserve">
        <g id="escenario_5">
            <image id="scene-bg" class="w-100 h-100" href="@/assets/scenes/caso_1_esc_5.png"></image>
        </g>
        <g id="escena_x5F_path">
            <path id="escenario_x5F_5_x5F_path" class="st0" d="M2117.3,610.4L1058.4-0.2L-0.5,611.2l0.1,698.6l0.1,0.4l1058.8,611v0.1h0.1l0.1,0.1v-0.1L2117.5,1310v-0.1h0.1V610.4H2117.3z"/>
            <rect id="activity5_1" fill="rgba(0,0,0,0)" x="850.9" y="1162.4" class="st1 activity" width="337" height="345.4"/>
            <rect id="activity5_2" fill="rgba(0,0,0,0)" x="422.2" y="1116.3" class="st1 activity" width="364.3" height="188.2"/>
            <rect id="activity5_3" fill="rgba(0,0,0,0)" x="377.5" y="850.4" class="st1 activity" width="385.8" height="245.1"/>
            <rect id="activity5_4" data-last="activity4_3" fill="rgba(0,0,0,0)" x="1369.8" y="363.4" class="st1 activity" width="294.6" height="341.7"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Map'
    }
</script>

<style>
    .st0{opacity:0.15;fill:#FFFFFF;}
    .st1{fill:#FFFFFF;fill-opacity:0;}
</style>