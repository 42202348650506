<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 2118 1921.8" style="enable-background:new 0 0 2118 1921.8;" xml:space="preserve">
        <g id="escenario_4">
            <image id="scene-bg" class="w-100 h-100" href="@/assets/scenes/caso_1_esc_4.png"></image>
        </g>
        <g id="escena_x5F_path">
            <path id="escenario_x5F_4_x5F_path" class="st0" d="M2116.7,609.9L1059.4,0.3L2,610.8l0.1,697.6l1057.4,610.5l1057.2-610.5V609.9z"/>
            <rect id="activity4_1" fill="rgb(0,0,0,0)" x="247.9" y="833.1" class="st1 activity" width="323.4" height="211.7"/>
            <rect id="activity4_2" fill="rgb(0,0,0,0)" x="1111.5" y="629.6" class="st1 activity" width="341.6" height="190.4"/>
            <rect id="activity4_3" fill="rgb(0,0,0,0)" x="576.6" y="1425.2" class="st1 activity" width="338.1" height="196.2"/>
            <rect id="activity4_4" data-last="activity4_3" fill="rgb(0,0,0,0)" x="1411.4" y="839.5" class="st1 activity" width="322.7" height="249"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Map'
    }
</script>

<style>
    .st0{opacity:0.15;fill:#FFFFFF;}
    .st1{fill:#FFFFFF;fill-opacity:0;}
</style>