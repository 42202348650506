<template>
</template>

<script>
import utils from '../../../utils'
export default {
    props: {
        activity: 
        {
            type: Object,
            required: true,
            default: {}
        }
    },
    mounted: function() {
        utils.AdjustGameScreen();
    }
}
</script>

<style>
</style>