const utils = {
    ToggleFullScreen : function() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            return false;
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                return true;
            }
        }
    },
    AdjustGameScreen: function()
    {
        let calc_height = ($(window).outerHeight() - $('#game-nav').outerHeight());
        $('#game-container').css('height', calc_height + "px !important");
        $('#game-container').height(calc_height);
    },
    StartCountDown: function(){
        var countdown_interval = setInterval(() =>{
            vueapp.$store.commit('SetCountDown', vueapp.$store.state.countdown - 1);
            var audio = vueapp.$store.state.sounds["BeepSound"];
            audio.pause();
            audio.currentTime = 0;
            audio.play();
            if(vueapp.$store.state.countdown <= 0) {
                clearInterval(vueapp.$store.state.countdown_interval);
                router.push({name:"map"}).catch((e)=>{console.log(e)});
                vueapp.$store.commit("SetTimer", new Date().getTime() + (60000*process.env.VUE_APP_COUNTDOWN_MINUTES));
            }
        }, 1000);
        vueapp.$store.commit('SetCountDownInterval', countdown_interval);
    },
    RouteExists(path) {
        return vueapp.$router.hasRoute(path);
    },
    Send() {
        //let xhr = new XMLHttpRequest();
        //xhr.open("POST", "/save_time", true);
        //xhr.setRequestHeader("Content-Type", "application/json");
        //xhr.onreadystatechange = function () {
        //    if (xhr.readyState === XMLHttpRequest.DONE) {
        //        if (xhr.status === 200) {
        //            console.log("Tiempo enviado al servidor correctamente.");
        //        } else {
        //            console.error("Error al enviar tiempo al servidor: " + xhr.status);
        //        }
        //    }
        //};
        //xhr.send(JSON.stringify({ tiempo: tiempo }));
    }
};


module.exports = utils;