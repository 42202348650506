<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 2118 1921.8" style="enable-background:new 0 0 2118 1921.8;" xml:space="preserve">
        <g id="escenario_1">
            <image id="scene-bg" class="w-100 h-100" href="@/assets/scenes/caso_1_esc_1.png"></image>
        </g>
        <g id="escena_x5F_path">
            <path id="escenario_1_path" class="st0" d="M2114.7,609.9L1057.4,0.3L0,610.8l0.1,697.6l1057.4,610.5l1057.2-610.5V609.9z"/>
            <rect id="activity1_1" x="1541.2" y="746.8" class="st1 activity" fill="rgba(0,0,0,0)" width="317.9" height="327.3"/>
            <rect id="activity1_2" x="369" y="1121.5" class="st1 activity" fill="rgba(0,0,0,0)" width="389" height="203.2"/>
            <rect id="activity1_3" x="323.2" y="837.3" class="st1 activity" fill="rgba(0,0,0,0)" width="365" height="231.1"/>
            <rect id="activity1_4" x="1160.2" y="937.4" class="st1 activity" fill="rgba(0,0,0,0)" width="331.6" height="343.3"/>
            <rect id="activity1_5" x="1167.9" y="621" class="st1 activity" fill="rgba(0,0,0,0)" width="350.3" height="226.6"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Map'
    }
</script>

<style>
    .st0{opacity:0.15;fill:#FFFFFF;}
    .st1{fill:#FFFFFF;fill-opacity:0;}
</style>