<template>
    <div v-if="activity" class="w-100">
        <div class="row w-100 h-100">
            <div :class="activity.left_col + ' ps-5 pe-5'">
                <h3 class="title-scene mt-5">{{ activity.title }}</h3>
                <p class="description-scene">{{ activity.intro_text }}</p>
                <div class="w-100 d-flex justify-content-center align-items-center" v-if="activity.clue_text != null">
                    <button v-if="!$store.state.clue_locked && !GetClueStatus" class="pt-2 pb-2 pe-5 ps-5 clues clue-button" @click="ShowClue">¡PISTA!</button>
                    <div v-if="GetClueStatus" class="ms-3 clue-text d-flex justify-content-center align-items-center flex-column">
                        <h3>¡PISTA!</h3>
                        <text v-html="activity.clue_text"></text>
                    </div>
                    <small v-if="$store.state.clue_locked && !GetClueStatus" class="clue-msg">Hace muy poco que pediste una pista, inténtalo más tarde</small>
                </div>
            </div>
            <div :class="activity.right_col + '  ps-5 pe-5'">
                <h3 v-if="activity.options.length > 1" class="text-rockwell question-title mt-5 text-start">{{ activity.question }}</h3>
                <h3 v-else class="text-rockwell question-title mt-5 text-center">{{ activity.question }}</h3>
                <div class="row">
                    <div class="col-12">
                        <div class="w-100 d-flex justify-content-center align-items-center" id="act-checks" v-for="option in activity.options">
                            <label class="mt-2 text-oswald" :for="'btn-check-'+option.value" v-if="activity.options.length > 1">{{ option.text }}</label>
                            <input type="text" class="ms-2 w-auto form-control mt-2 border-secondary" placeholder="Escribe el texto.." :id="'btn-check-'+option.value" v-model="option.value">
                        </div>
                    </div>
                </div>
                <div class="row d-flex justify-content-center align-items-center">
                    <button v-if="!locked" class="btn btn-danger mt-3 mb-3 text-center text-oswald w-auto" @click="CheckActivity">Comprobar</button>
                    <div v-else class="alert alert-danger mt-2 animate__animated animate__shakeX w-auto">{{ activity.incorrect_msg }}</div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="$store.state.environment == 'development'" class="position-absolute bottom-0 start-0">
        {{ activity.correct_answer }}
    </div>
</template>

<script>
import Activity from '../Activity'

export default {
    extends: Activity,
    props: {
        activity: {
            type: Object,
            required: true,
            default: {
                left_col : "col-6",
                right_col : "col-6",
            }
        },
    },
    data(){
        return {
            check_timeout: null,
            locked: false,
            checkedOptions: null
        };
    },
    computed: {
        GetClueStatus() {
            return this.$store.state.scenes[this.activity.scene].clues.includes(this.activity.id);
        }
    },
    mounted() {
        if(this.$store.state.scenes[this.activity.scene].activities[this.activity.id])
            this.$router.push({name: this.activity.feedback});
    },
    methods: 
    {
        CheckActivity()
        {
            this.locked = true;

            let isCorrect = true;

            // Check if arrays are equal
            this.activity.options.forEach(opt => {
                // Compare the value of each option with its solution
                if (opt.value.toLowerCase().trim() !== opt.solution.toLowerCase().trim()) {
                    isCorrect = false; // If any option is incorrect, set isCorrect to false
                }
            });

            if (isCorrect) {
                // Inform the user that their selections are correct
                this.$store.commit("SetActivityStatus", 
                    {
                        scene: this.activity.scene,
                        act: this.activity.id,
                        status: true
                    }
                );
                var audio = vueapp.$store.state.sounds["CorrectSound"];
                audio.pause();
                audio.currentTime = 0;
                audio.play();
                
                // Update score
                this.$store.commit("SetScore", this.$store.state.score + this.activity.score);

                this.$router.push({name: this.activity.feedback});
            } else {
                // Inform the user that their selections are incorrect
                var audio = vueapp.$store.state.sounds["ErrorSound"];
                audio.pause();
                audio.currentTime = 0;
                audio.play();
            }
            
            this.check_timeout = setTimeout(() => {
                clearTimeout(this.check_timeout);
                this.locked = false;
            }, 1000);
        },
        ShowClue()
        {
            if(!this.$store.state.clue_locked){           
                this.$store.commit("SetActivityClue", 
                    {
                        scene: this.activity.scene,
                        act: this.activity.id
                    }
                );
                this.$store.commit("SetClueTimestamp", Date.now());
            }
        },
        onDragStart(index, event) {
            event.dataTransfer.setData('index', index);
        },
        onDrop(index, event) {
            const draggedIndex = event.dataTransfer.getData('index');
            const draggedOption = this.activity.options[draggedIndex];
            if(draggedOption !== undefined) {
                this.activity.options.splice(draggedIndex, 1);
                this.activity.options.splice(index, 0, draggedOption);
            }
            event.preventDefault();
        }
    }
}
</script>