<template>
    <div v-if="activity" class="w-100">
        <div class="row w-100 h-100 m-0">
            <div class="col-md-4">
                <h3 class="title-scene mt-5">{{ activity.title }}</h3>
                <p class="description-scene">{{ activity.intro_text }}</p>
                <div class="w-100 d-flex justify-content-center align-items-center" v-if="activity.clue_text != null">
                    <button v-if="!$store.state.clue_locked && !GetClueStatus" class="pt-2 pb-2 pe-5 ps-5 clues clue-button" @click="ShowClue">¡PISTA!</button>
                    <div v-if="GetClueStatus" class="ms-3 clue-text d-flex justify-content-center align-items-center flex-column">
                        <h3>¡PISTA!</h3>
                        <text v-html="activity.clue_text"></text>
                    </div>
                    <small v-if="$store.state.clue_locked && !GetClueStatus" class="clue-msg">Hace muy poco que pediste una pista, inténtalo más tarde</small>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column text-center text-info">
                    <span class="mt-2">Arrastra y suelta los elementos de la segunda columna para ordenar las frases</span>
                    <span class="mt-2">¡Ten cuidado! al arrastrar un elemento a una posición los demás se desplazan. Debes asignarlos en orden.</span>
                </div>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <h3 class="text-rockwell question-title mt-5 text-start">{{ activity.question }}</h3>
                </div>
                <div class="row" v-if="activity.format == 'table'">
                    <table>
                        <tbody>
                            <tr v-for="(itm, key) in activity.tableData" @dragover.prevent @drop="onCellDrop(key, $event)">
                                <th v-for="(it, keyit) in itm" :draggable="(key > 0 && keyit == activity.dragCol) ? 'true' : 'false'"  @dragstart="onCellDragStart(key, $event)" v-html="(key > 0 && keyit == activity.dragCol) ? it.name : it"></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else-if="activity.format == 'list'" class="row">                    
                    <div class="col-6">
                        <div class="w-100 left-col mt-2" v-for="(option, index) in activity.titles" :key="index">
                            <input type="radio" class="btn-check" :id="'btn-check-'+option.value">
                            <label class="btn btn-activity w-100 text-oswald h-100 d-flex justify-content-center align-items-center">
                                <div v-if="$store.state.environment == 'development'">{{ option.id }} -</div>
                                {{ option.content }}
                            </label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="w-100 right-col mt-2" v-for="(option, index) in activity.options" :key="index" :draggable="!locked" @dragover.prevent @touchend="onTouchEnd(index, $event)" @drop="onDrop(index, $event)">
                            <label class="btn btn-activity w-100 text-oswald h-100 d-flex justify-content-center align-items-center draggable" draggable="true" @touchstart="onTouchStart(index, $event)" @dragstart="onDragStart(index, $event)" :data-id="index">
                                <div v-if="$store.state.environment == 'development'">{{ option.id }} -</div>
                                {{ option.name }}
                            </label>
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col">
                        <div class="w-100 right-col mt-2" v-for="(option, index) in activity.options" :key="index" :draggable="!locked" @dragover.prevent @touchend="onTouchEnd(index, $event)" @drop="onDrop(index, $event)">
                            <label class="btn btn-activity w-100 text-oswald h-100 d-flex justify-content-center align-items-center" draggable="true" @touchstart="onTouchStart(index, $event)" @dragstart="onDragStart(index, $event)">{{ ($store.state.environment == 'development') ? option.id : '' }}{{ option.name }}</label>
                        </div>
                    </div>
                </div>
                <div class="row d-flex justify-content-start align-items-center flex-column">
                    <button v-if="!locked" class="btn btn-danger mt-3 mb-3 text-center text-oswald w-auto" @click="CheckActivity">Comprobar</button>
                    <div v-else class="alert alert-danger mt-2 animate__animated animate__shakeX w-auto">{{ activity.incorrect_msg }}</div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="$store.state.environment == 'development'" class="position-absolute bottom-0 start-0">
        {{ activity.correct_answer }}
    </div>
</template>

<script>
import Activity from '../Activity'

export default {
    extends: Activity,
    props: {
        activity: {
            type: Object,
            required: true,
            default: {}
        },
    },
    data(){
        return {
            loaded: false,
            check_timeout: null,
            locked: false,
            checkedOptions: null,
            dragging_itm: null,
            drop_idx: null,
            draggedOption: null,
            touchGhost: null
        };
    },
    computed: {
        GetClueStatus() {
            return this.$store.state.scenes[this.activity.scene].clues.includes(this.activity.id);
        }
    },
    mounted() {
        if(this.$store.state.scenes[this.activity.scene].activities[this.activity.id])
            this.$router.push({name: this.activity.feedback});

        this.AdjustRows();

        window.addEventListener("resize", this.AdjustRows);
        this.attachTouchListeners(); 
    },
    methods: 
    {
        CheckActivity()
        {
            this.locked = true;
            this.checkedOptions = this.activity.options.map(option => option.id);

            // Check if arrays are equal
            const isCorrect = JSON.stringify(this.activity.correct_answer) === JSON.stringify(this.checkedOptions);
            
            if (isCorrect) 
            {
                this.$store.commit("SetActivityStatus", 
                    {
                        scene: this.activity.scene,
                        act: this.activity.id,
                        status: true
                    }
                );
                var audio = vueapp.$store.state.sounds["CorrectSound"];
                audio.pause();
                audio.currentTime = 0;
                audio.play();

                // Update score
                this.$store.commit("SetScore", this.$store.state.score + this.activity.score);

                this.$router.push({name: this.activity.feedback});
            } 
            else 
            {
                var audio = vueapp.$store.state.sounds["ErrorSound"];
                audio.pause();
                audio.currentTime = 0;
                audio.play();
            }
            
            this.check_timeout = setTimeout(() => {
                clearTimeout(this.check_timeout);
                this.locked = false;
            }, 1000);
        },
        // Method to attach touch event listeners to draggable elements
        attachTouchListeners() {
            const draggableElements = document.querySelectorAll('.draggable');

            draggableElements.forEach(element => {
                element.addEventListener('touchmove', this.onTouchMove);
            });
        },
        ShowClue()
        {
            if(!this.$store.state.clue_locked){           
                this.$store.commit("SetActivityClue", 
                    {
                        scene: this.activity.scene,
                        act: this.activity.id
                    }
                );
                this.$store.commit("SetClueTimestamp", Date.now());
            }
        },
        CreateGhostElement(x, y) {
            let ghost = document.createElement("div");
            ghost.classList.add("ghost");
            ghost.classList.add("btn");
            ghost.classList.add("btn-activity");
            ghost.classList.add("text-oswald");
            ghost.classList.add("d-flex");
            ghost.classList.add("justify-content-center");
            ghost.classList.add("align-items-center");
            ghost.classList.add("draggable");

            ghost.style.position = "absolute";
            ghost.style.top = this.dragging_itm.getBoundingClientRect().y + "px";
            ghost.style.left = this.dragging_itm.getBoundingClientRect().x + "px";
            ghost.style.width = this.dragging_itm.getBoundingClientRect().width + "px";
            ghost.style.height = this.dragging_itm.getBoundingClientRect().height + "px";

            ghost.style.backgroundImage = `radial-gradient(ellipse 60% 50% at ${x}px ${y}px, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))`;
            ghost.innerHTML = this.dragging_itm.innerHTML;
            document.body.appendChild(ghost);
            this.ghostElement = ghost; // Store reference to the ghost element
        },
        // Touch event handlers
        onTouchStart(index, event) {
            event.preventDefault();
            event.stopPropagation();
            const touch = event.touches[0];
            this.draggedIndex = index;
            this.draggedOption = this.activity.options[index];
            this.dragging_itm = event.target;
            this.touchGhost = {
                initialX: touch.clientX - event.target.getBoundingClientRect().left,
                initialY: touch.clientY - event.target.getBoundingClientRect().top
            };
            this.CreateGhostElement(this.touchGhost.initialX, this.touchGhost.initialY);
        },
        onTouchMove(event) {
            event.preventDefault();
            event.stopPropagation();
            if (this.touchGhost !== null) {
                const touch = event.touches[0];
                const touchX = touch.clientX;
                const touchY = touch.clientY;
                const newX = touch.clientX - this.touchGhost.initialX;
                const newY = touch.clientY - this.touchGhost.initialY;
                // Get the draggable element at the touch coordinates
                const elementAtTouch = document.elementFromPoint(touchX, touchY);
                if(elementAtTouch != event.target)
                {
                    if(elementAtTouch.dataset.id)
                    {
                        this.drop_idx = elementAtTouch.dataset.id;
                        console.log(this.drop_idx)
                    }                 
                }                
                // Update ghost element position
                if (this.ghostElement) {
                    this.ghostElement.style.top = newY + "px";
                    this.ghostElement.style.left = newX + "px";
                    this.ghostElement.style.width = this.dragging_itm.getBoundingClientRect().width + "px";
                    this.ghostElement.style.height = this.dragging_itm.getBoundingClientRect().height + "px";
                }
            }
        },
        onTouchEnd(index, event) {
            event.preventDefault();
            event.stopPropagation();
            
            // Remove ghost element
            if (this.ghostElement) {
                document.body.removeChild(this.ghostElement);
                this.ghostElement = null;
            }
            
            if (this.drop_idx !== null) {
                const draggedIndex = index;
                const draggedOption = this.activity.options[draggedIndex];
                if (draggedOption !== undefined) {
                    this.activity.options.splice(draggedIndex, 1);
                    this.activity.options.splice(this.drop_idx, 0, draggedOption);
                }
                this.drop_idx = null; // Reset drop index
            }
        },
        onDragStart(index, event) {
            event.dataTransfer.setData('index', index);
        },
        onDrop(index, event) {
            const draggedIndex = event.dataTransfer.getData('index');
            const draggedOption = this.activity.options[draggedIndex];
            if(draggedOption !== undefined) {
                this.activity.options.splice(draggedIndex, 1);
                this.activity.options.splice(index, 0, draggedOption);
            }
            event.preventDefault();
        },
        onCellDragStart(index, event) {
            event.dataTransfer.setData('index', index);
        },
        onCellDrop(index, event) {
            const draggedIndex = event.dataTransfer.getData('index');
            const dragingOption = this.activity.tableData[draggedIndex][this.activity.dragCol];
            const dropedOption = this.activity.tableData[index][this.activity.dragCol];            
            if(dragingOption !== undefined && dropedOption !== undefined) {
                this.activity.tableData[draggedIndex][this.activity.dragCol] = dropedOption;
                this.activity.tableData[index][this.activity.dragCol] = dragingOption;
                this.activity.options = [];
                this.activity.tableData.forEach((it, key) => {
                    if(key>0)
                        this.activity.options.push(it[this.activity.dragCol])
                });
            }
            event.preventDefault();
        },
        AdjustRows() {
            let left_rows = $('.left-col').toArray();
            let right_rows = $('.right-col').toArray();

            let left_max = 0, right_max = 0;

            left_rows.forEach((it) => {
                it.style.height = '';
                it.style.height = 'inherit !important';
                if(left_max < it.offsetHeight)
                    left_max = it.offsetHeight;
            });

            right_rows.forEach((it) => {
                it.style.height = '';
                it.style.height = 'inherit !important';
                if(right_max < it.offsetHeight)
                    right_max = it.offsetHeight;
            });

            let height = 10;

            if(left_max < right_max) height = right_max;
            else height = left_max;

            if(height>0){
                // Then adjust each item with the max value, if it is left_max or if it is right_max
                left_rows.forEach((it) => {
                    it.style.height = height + 'px';
                });

                right_rows.forEach((it) => {
                    it.style.height = height + 'px';
                });
            }

        }
    }
}
</script>

<style>
th[draggable="true"] {
    cursor: move;
    border: 2px dashed #ccc;
    padding: 10px;
}

table {
    border-collapse: collapse;
    width: 100%;
}

td, th {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}
.ghost {
    position: fixed;
    pointer-events: none;
    opacity: 0.8;
    background-color: #f3f3f300 !important;
    color: #ffffff;
    padding: 10px;
    z-index: 1000;
}

</style>