<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="row h-100">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                        <img class="img-fluid" src="@/assets/help/icono_escenario.png" alt="">
                    </div>
                    <div class="col-md-8 d-flex justify-content-center align-items-center text-oswald">
                        El acceso a los escenarios desbloqueados se realiza haciendo click sobre ellos.
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row h-100">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                        <img class="img-fluid" src="@/assets/help/icono_pantalla.png" alt="">
                    </div>
                    <div class="col-md-8 d-flex justify-content-center align-items-center text-oswald">
                        Para una óptima visualización del caso es necesario utilizar la pantalla completa.
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="row h-100">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                        <img class="img-fluid" src="@/assets/help/icono_manita_1.png" alt="">
                    </div>
                    <div class="col-md-8 d-flex justify-content-center align-items-center text-oswald">
                        Dentro de cada escenario podrás acceder a las actividades pinchando en las manitas. La manita roja señala la actividad por la que debes comenzar.
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row h-100">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                        <img class="img-fluid" src="@/assets/help/icono_manita_2.png" alt="">
                    </div>
                    <div class="col-md-8 d-flex justify-content-center align-items-center text-oswald">
                        En cada manita hay una información o actividad para resolver. Al resolver correctamente una actividad obtendrás una nueva información.
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="row h-100">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                        <img class="img-fluid" src="@/assets/help/icono_escenario_act.png" alt="">
                    </div>
                    <div class="col-md-8 d-flex justify-content-center align-items-center text-oswald">
                        No hay un orden a seguir. Si entras en una actividad y no dispones de toda la información para resolverla, vuelve atrás y clica en otra actividad hasta que encuentres el dato o datos que te falten.
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row h-100">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                        <img class="img-fluid" src="@/assets/help/icono_bloqueo.png" alt="">
                    </div>
                    <div class="col-md-8 d-flex justify-content-center align-items-center text-oswald">
                        Todos los escenarios disponen de una actividad final. Esta actividad estará bloqueada hasta que se resuelvan todas las demás. Al completar un escenario se desbloqueará el siguiente.
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="row h-100">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                        <img class="img-fluid" src="@/assets/help/icono_volver.png" alt="">
                    </div>
                    <div class="col-md-8 d-flex justify-content-center align-items-center text-oswald">
                        Siempre puedes volver a una actividad resuelta o escenario previo a revisar la información disponible.
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row h-100">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                        <img class="img-fluid" src="@/assets/help/icono_reloj.png" alt="">
                    </div>
                    <div class="col-md-8 d-flex justify-content-center align-items-center text-oswald">
                        No te olvides del reloj, ¡Date prisa que el tiempo es limitado!
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>