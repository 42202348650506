import Vuex from 'vuex';
import { watch } from 'vue';

const getDefaultState = () => {
    return {
        environment: null,
        navbar: 'white',
        game_start: null,
        //CountDown timer
        countdown: 4,
        countdown_interval: null,
        //Global timer
        timer: null,
        timer_interval: null,
        /*
            starting    - The game is on the initial countdown view
            started     - The game is already initialized, the other countdown on the navbar already got fired.
            finished    - The game is finished.
        */
        // Last saved url
        last_url: null,
        // Save activities status
        activities: [],
        //Clues
        clue_timestamp: null,
        clue_locked: false,
        //Scenes
        scenes: {
            scene1:{
                activities: {},
                clues: [],
                locked: false
            },
            scene2:{
                activities: {},
                clues: [],
                locked: true
            },
            scene3:{
                activities: {},
                clues: [],
                locked: true
            },
            scene4:{
                activities: {},
                clues: [],
                locked: true
            },
            scene5:{
                activities: {},
                clues: [],
                locked: true
            },
        },
        sounds: [],
        game_over: true,
        score: 0,
        total_time: null
    }
}


// initial state
const state = getDefaultState();

const store = new Vuex.Store({
    state,
    mutations: {
        // New functions
		InitialiseStore(state) {
            let store = localStorage.getItem('store'+window.game_id);
			// Check if the ID exists
			if(store) {
				// Replace the state object with the stored item
				Object.assign(state, JSON.parse(store))
			}
		},
        SetEnvironment(state, param)
        {
            state.environment = param;
        },
        SetNavBar(state, param)
        {
            state.navbar = param;
        },
        SetGameStart(state, param)
        {
            state.game_start = param;
        },
        SetCountDown(state, param) {
            state.countdown = param;
        },
        SetCountDownInterval(state, param){
            state.countdown_interval = param;
        },
        ResetGlobalState(state) {
            Object.assign(state, getDefaultState())
        },
        SetTimer(state, param) {
            state.timer = param;
        },
        SetLastURL(state, param) {
            state.last_url = param;
        },
        SetActivityStatus(state, param) {
            state.scenes[param.scene].activities[param.act] = param.status;
        },
        SetSceneStatus(state, param) {
            state.scenes[param.scene].locked = param.status;
        },
        SetClueTimestamp(state, param) {
            if(!state.clue_locked) {
                state.clue_timestamp = param;
                state.clue_locked = true;
                setTimeout(() => {
                    state.clue_timestamp = null;
                    state.clue_locked = false;
                }, 10000);
            }
        },
        SetActivityClue(state, param) {
            state.scenes[param.scene].clues.push(param.act);
        },
        SetSound(state, param) {
            state.sounds[param.name] = param.sound;
        },
        SetGameOver(state, param) {
            state.game_over = param;
        },
        SetScore(state, param) {
            state.score = param;
        },
        SetTotalTime(state, param) {
            state.total_time = param;
        }
    },
    actions: {}
})

// Assuming you have your Vue app instance created

watch(
    () => store.state,
    (state) => {
        localStorage.setItem('store' + window.game_id, JSON.stringify(state));
    },
    { deep: true }
);

export default store