<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="activity-container  w-100 h-100">
                <div class="row w-100 h-100 m-0 d-flex justify-content-center align-items-start">
                    <div class="col-md-6 feedback1-5-text">
                        <div class="p-4 text-oswald">
                            <p class="m-0">Efectivamente se trata de una descompensación hiperglucémica aguda por lo que es necesario tratarla de urgencia con:</p>
                            <p>20 U de insulina glargina 100. Insistimos en aumentar el consumo de líquidos, se le cita al día siguiente en la consulta con su médico de familia.</p>
                            <p class="text-center">Debes conservar estas letras para el final</p>
                            <h2 class="text-center feedback-big-text">A N I</h2>
                            <h5 class="text-center feedback-final-sentence font-weight-bold w-100 mt-5">Mañana, Maria, la recibirá su médico de familia en la consulta para seguimiento. </h5>
                        </div>
                    </div>    
                    <div class="col-md-6 d-flex justify-content-center align-items-center" id="gallery">
                        <a :href="img" 
                            data-pswp-width="947" 
                            data-pswp-height="669" 
                            target="_blank" class="w-100 h-100 d-flex justify-content-center align-items-center p-3">
                            <img id="feedback-image" class="mt-3" src="@/assets/esc_1_act_5.png" alt="feedback-image">
                        </a>
                    </div> 
                </div>
            </div>
        </div>
        <button class="position-absolute btn btn-danger bottom-0 end-0 rounded m-5 text-oswald" onclick="router.push({name:'scene1'});">Volver</button>
    </div>
</template>

<script>
import utils from '../../../utils'
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
export default {
    name: 'Feedback1_5',
    data(){
        return {
            img : require('@/assets/esc_1_act_5.png')
        };
    },
    mounted(){
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity1_5_feedback');
        this.$store.commit("SetSceneStatus", {scene: 'scene2',status: false});
        const lightbox = new PhotoSwipeLightbox({
            gallery: '#gallery', 
            children: 'a',
            pswpModule: () => import('photoswipe')
        });
        lightbox.init();
    },
}
</script>

<style scoped>
#feedback-image{
    max-width: 100%;
    border: 4px solid #888bb4;
    max-height: 100%;
}
</style>