<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <Checks :activity="activity"></Checks>
        <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene1'});">Volver</button>
    </div>
</template>

<script>
import Checks from './../activity/types/Checks.vue'
import utils from '../../../utils'
export default {
    components: {
        Checks
    },
    data(){
        return {
            activity:
            {
                id : "activity1_3",
                scene : "scene1",
                title : "Constantes y pruebas complementarias",
                intro_text : "Tras la anamnesis y la exploración, que exploraciones o pruebas complementarias realizarías en urgencias de atención primaria" ,
                question : " ¿Qué constantes y pruebas complementarias solicitarías?",
                options : [
                    {
                        text: 'Tensión arterial',
                        value: 0,
                    },
                    {
                        text: 'Glucemia capilar',
                        value: 1,
                    },
                    {
                        text: 'Saturación de oxígeno aire ambiente',
                        value: 2,
                    },
                    {
                        text: 'EKG',
                        value: 3,
                    },
                    {
                        text: 'Nueva PCR',
                        value: 4,
                    },
                    {
                        text: 'Tira de orina',
                        value: 5,
                    },
                    {
                        text: 'Cetonas capilares',
                        value: 6,
                    },
                    {
                        text: 'Ecografía urgente AP',
                        value: 7,
                    },
                    {
                        text: 'Analítica de sangre urgente',
                        value: 8,
                    }
                ],
                correct_answer: [
                    0, 1, 2, 3, 5, 6
                ],
                score: 10,
                clue_text : "En total serán necesarias 6 peticiones",
                incorrect_msg : "La respuesta es incorrecta",
                feedback : "activity1_3_feedback"
            }
        };
    },
    updated: function(){
        this.$store.commit("SetNavBar", 'red');
    },
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity1_3');
    }
}
</script>