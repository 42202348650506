<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <Text :activity="activity"></Text>
        <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene5'});">Volver</button>
    </div>
</template>

<script>
import Text from './../activity/types/Text.vue'
import utils from '../../../utils'
export default {
    components: {
        Text
    },
    data(){
        return {
            activity:
            {
                id : "activity5_4",
                scene : "scene5",
                title : "Casi hemos acabado, ¿Qué tratamiento le planteamos?",
                intro_text : "Bien! Una última pregunta y habrás conseguido resolver el caso de Maria. Ya sabemos cual es el diagnóstico, por eso...",
                question : "¿Qué habría que hacer ahora? Combina las letras recibidas a lo largo del escape-room para completar ambos enunciados",
                options : [
                    {
                        id:0,
                        text: 'Añadir',
                        solution: 'metformina',
                        value: '',
                    },
                    {
                        id:1,
                        text: 'Reducir progresivamente la dosis de',
                        solution: 'insulina',
                        value: '',
                    }
                ],
                score: 10,
                correct_answer: 3,
                clue_text : null,
                incorrect_msg : "La respuesta es incorrecta",
                feedback : "activity5_4_feedback",
                left_col : "col-md-5",
                right_col : "col-md-7"
            }
        };
    },
    updated: function(){
        this.$store.commit("SetNavBar", 'red');
    },
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity5_4');
    }
}
</script>