<template>
    <div id="start-background" class="w-100">
        <div id="start" class="row h-100 w-100 m-0 p-3 overflow-auto" :style="cssProps">
            <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4 d-flex justify-content-center align-items-center flex-column">                
                <div id="case-text" class="text-oswald fw-bold d-flex justify-content-end align-items-center">
                    <text class="m-0 me-4">CASO {{ caso_clinico }}</text>
                </div>             
                <div class="d-flex justify-content-center align-items-center flex-column">
                    <p class="text-lato fw-bold">
                        María es una paciente de nuestro cupo de 42 años. No presenta antecedentes médico-quirúrgicos de interés ni hábitos tóxicos. Los últimos 14 días hemos estado haciendo seguimiento tras comenzar con anosmia y astenia y ser diagnosticada mediante PCR de infección por SARs-COV-2. El día 6 de evolución precisó realización de radiografía de tórax por aumento de la tos seca y disnea, asociando saturación de oxígeno en aire ambiente del 93% por lo que se remitió a urgencias donde evidenciaron ocupación radiológica compatible con patrón intersticial con afectación bilobar en grado moderado, precisando ingreso hospitalario con oxigenoterapia durante 48 horas en el cual se inició tratamiento con dexametasona 6mg cada 24h y enoxaparina 40mg (4000 UI) una vez al día ambos durante 7 días.
                    </p>
                    <button type="button" id="intro-button" class="btn btn-light font-weight-bold text-oswald fw-bold ps-5 pe-5" @click="Start()">COMENZAR</button>
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-7 col-xl-8"></div>
            <!--img id="personaje_1" src="@/assets/personaje_1.png" alt="personaje_1"-->
        </div>
    </div>
</template>

<script>
    import utils from '../utils'
    export default {
        name: 'Start',
        data(){
            return {
                cssProps: {
                    backgroundImage: `url(${require('@/assets/ilustracion_fondo_con_margen-min.png')})`
                },
                caso_clinico: process.env.VUE_APP_GAME_ID
            };
        },
        updated(){
            utils.AdjustGameScreen();
        },
        mounted()
        {
            this.$store.commit("SetNavBar", 'red');
            utils.AdjustGameScreen();
            setTimeout(() => {                
                utils.AdjustGameScreen();
            }, 1000);
        },
        methods: {
            Start: function()
            {
                this.$store.commit("SetGameStart", Date.now());
                this.$store.commit("SetLastURL", 'start');
                router.push({name:"countdown"});
            }
        }
    }
</script>

<style scoped>

    #start{
        /*background-image: url('../assets/ilustracion_fondo_con_margen.png');*/
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: var(--app-start-background) !important;
        color: var(--app-start-color) !important;
    }

    #personaje_1{
        position: absolute;
        right: 150px;
        bottom: 0px;
        width: 20%;
    }

    #case-text
    {
        width: 203px;
        height: 72px;
        background-image: url(@/assets/logo_llave.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    #case-text text
    {
        font-size: 27px !important;
    }
</style>
