<template>
    <div id="game-app" ref="game-app" class="w-100 h-100 d-flex">
        <!-- Navigation bar -->
        <navbar id="game-nav" class="navbar_component"></navbar>
        <!-- Game content -->    
        <game id="game-container" ref="game-container"></game>
        <!-- Modal help -->        
        <div class="modal" id="modal-help" ref="modal-help" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                    <div class="modal-header d-flex justify-content-between">
                        <h5 class="modal-title"><span class='text-white text-rockwell'>Ayuda</span></h5>
                        <button type="button" class="btn text-white" data-bs-dismiss="modal"><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="modal-body">
                        <help></help>
                    </div>
                </div>
            </div>
        </div>        
        <!-- Modal reset -->
        <div class="modal" id="modal-center" ref="modal-center" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header d-flex justify-content-between">
                        <h5 class="modal-title"><span class='text-white text-rockwell'>¡Cuidado!</span></h5>
                        <button type="button" class="btn text-white" data-bs-dismiss="modal"><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="modal-body">
                        <p class="my-4 text-oswald">¿Esta seguro que desea reiniciar el juego?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn text-white btn-cancel" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn text-white btn-accept" @click="ResetDemo()" data-bs-dismiss="modal">Continuar</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-none">
            <audio id="beep" class="d-none">
                <source src="@/assets/sounds/beepcutted.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
            <audio id="end" class="d-none">
                <source src="@/assets/sounds/end.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
            <audio id="error" class="d-none">
                <source src="@/assets/sounds/error.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
            <audio id="correct" class="d-none">
                <source src="@/assets/sounds/correct.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
            <audio id="gameover" class="d-none">
                <source src="@/assets/sounds/game_over.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
        </div>
    </div>
</template>

<script>
    //Navigation bar
    import navbar from './components/NavBar.vue'
    import game from './components/Game.vue'
    import Help from './views/Help'
    import utils from './utils'
    export default {
        name: 'app',
        data() { 
            return {
                start_time: new Date()
            };
        },
        components: {
            navbar, game, Help
        },
        mounted: function() {

            //document.addEventListener("DOMContentLoaded", function(event) {
            //    let actual_time = new Date();
            //    let passed_time = Math.floor((actual_time - this.start_time) / 60000);
            //    console.log("Tiempo en la página: " + passed_time + " minutos");
            //    // TODO: Send Ajax info to the server
            //});

            //window.addEventListener("beforeunload", function(event) {
            //    let final_time = new Date();
            //    let total_time = Math.floor((final_time - this.start_time) / 60000);
            //    this.$store.commit("SetTotalTime", total_time);
            //    alert("Tiempo total en la página: " + this.$store.state.total_time + " minutos");
            //    // TODO: Send Ajax info to the server
            //});

            this.$store.commit("SetNavBar", 'white');
            window.addEventListener("resize", utils.AdjustGameScreen);

            const beep = document.getElementById('beep');
            const end = document.getElementById('end');
            const error = document.getElementById('error');
            const correct = document.getElementById('correct');
            const gameover = document.getElementById('gameover');

            beep.addEventListener('loadeddata', () => {
                this.$store.commit("SetSound", {name: "BeepSound", sound: beep});
            });
            end.addEventListener('loadeddata', () => {
                this.$store.commit("SetSound", {name: "EndSound", sound: end});
            });
            error.addEventListener('loadeddata', () => {
                this.$store.commit("SetSound", {name: "ErrorSound", sound: error});
            });
            correct.addEventListener('loadeddata', () => {
                this.$store.commit("SetSound", {name: "CorrectSound", sound: correct});
            });
            gameover.addEventListener('loadeddata', () => {
                this.$store.commit("SetSound", {name: "GameOverSound", sound: gameover});
            });
            utils.AdjustGameScreen();
        },
        methods:{      
            ResetDemo: function()
            {
                vueapp.$store.commit('ResetGlobalState');
                window.location.href = "/game_"+process.env.VUE_APP_GAME_ID+"/";
            },
        }
    }
</script>

<style>

    @font-face {
        font-family: 'Rockwell Nova';
        src: url("./assets/fonts/rockwell-nova/RockwellNova-Bold.ttf");
    }

    @font-face {
        font-family: 'Lato';
        src: url("./assets/fonts/lato/Lato-Regular.ttf");
    }

    @font-face {
        font-family: 'Oswald Medium';
        src: url("./assets/fonts/oswald/Oswald-Medium.ttf");
    }

    @font-face {
        font-family: 'Oswald Light';
        src: url("./assets/fonts/oswald/Oswald-Light.ttf");
    }

    :root {
        /* Navbar, clues, border inputs, feedback text */
        --ripple-color: red;
        --ripple-size: 25px;
        --ripple-border: 2px;
        --ripple-dark-color: rgb(0, 0, 0);
        --ripple-dark-size: 40px;
        --ripple-dark-border: 4px;
        --main-color: #ffffff;
        --text-color: rgb(255, 255, 255);
        --clock-color: rgb(242, 242, 242);
        --clock-text-color: rgb(213, 43, 43);
        --clock-face-color: rgb(242, 242, 242);
        --clock-back-color: rgb(218, 218, 218);
        --app-start-background: #a5e0db;
        --app-start-title-color: #4b4b4b;
        --app-start-color: #545b62;        
        --app-start-button-background: #e2457c;
        --app-start-button-color: white;
        --app-start-button-border: none;
        --activity-background: #e1e2ec;
        --activity-border: #888bb4;
        --activity-dialogue-background: #a5e0db;
        --activity-dialogue-color:  #434648;
        --btn-activity-bg-color:  #008acb;
        --btn-activity-bg-color-active: #434648;
        --btn-activity-bg-color-checked: #545b62;
        --btn-activity-border-radius:  35px;
        --btn-secondary-bg-color: rgb(136 139 180);
        --bg-main-color: #00b0ab;
        --clue-text-background: var(--bg-main-color);
        --clue-text-color: var(--text-color);
        --clue-text-border: 1px solid var(--app-start-background);
        --clue-text-border-radius: 18px;
        --clue-button-background: var(--bg-main-color);
        --clue-button-color: var(--text-color);
        --clue-button-border: 1px solid var(--app-start-background);
        --clue-button-border-radius: 35px;
        --speech-text-background-color: #ffffffe0;
        --speech-text-color: #1b1b1bf5;
        --speech-text-text-size: 1.5em;
    }

    html, body{
        user-select: none;
        width: 100% !important;
        height: 100% !important;
        margin: 0px !important;
        overflow: hidden;
    }

    #app 
    {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        width: 100% !important;
        height: 100% !important;
        overflow: hidden;
    }

    #game-app
    {
        display: flex !important;
        flex-direction: column;
        height: 100vh !important;
        overflow: hidden;
    }

    #game-container {
        overflow-y: auto;
    }

    .logo
    {
        height: 40px !important;
    }

    #start-background
    {
        /*background-color: var(--bg-main-color);*/
        background-color: #a5e0db;
    }

    .text-oswald
    {
        font-family: 'Oswald Medium' !important;
    }

    .text-rockwell
    {
        font-family: 'Rockwell Nova' !important;
    }

    .text-lato
    {
        font-family: 'Lato' !important;
    }

    .modal-header
    {
        background-color: var(--bg-main-color)!important;
    }

    #start
    {
        background-color: var(--bg-main-color)!important;
        color: white;
    }

    .info-btn i{
        font-size: 25px !important;
    }

    .time-box{
        background-color: var(--clock-color) !important;
    }

    .time-box > div.base .base-b{
        background-color: var(--clock-color) !important;
    }

    .time-box > div.face{
        background-color: var(--clock-face-color) !important;
    }

    .time-box > div.back{
        background-color: var(--clock-back-color) !important;
    }

    .time-text{
        font-size: 30px;
    }

    #title-start
    {
        color: var(--app-start-title-color);
    }

    .btn-cancel
    {
        color: white!important;
        background-color: var(--btn-secondary-bg-color)!important;
    }

    .btn-accept
    {
        color: white!important;
        background-color: var(--bg-main-color)!important;
    }

    #countdown-dor
    {
        width: 30%;
    }

    #intro-button
    {
        font-size: 2em;
        background-color: var(--app-start-button-background);
        color: var(--app-start-button-color);
        border: var(--app-start-button-border);
    }

    /** Clue styles */
    .clues {
        background: var(--clue-text-background);
        border: var(--clue-text-border);
        color: var(--clue-button-color) !important;
        font-weight: bold;
    }

    .clue-button {
        background: var(--clue-text-background);
        border: var(--clue-text-border);
        color: var(--clue-button-color) !important;
        border-radius: var(--clue-button-border-radius) !important;
    }
    
    .clue-show {
        font-size: 19px;
        color: var(--clue-button-background) !important;
        border: 3px solid var(--clue-button-border) !important;
        border-color: var(--clue-button-border) !important;
    }

    .clue-text {
        background: var(--clue-text-background);
        color: var(--clue-text-color);
        border: var(--clue-text-border);
        border-radius: var(--clue-text-border-radius);
        padding: 10px;
        font-weight: bold;
    }

    .clue-msg {
        font-style: italic;
        color: var(--clue-text-background) !important;
        font-size: 1.1em;
    }

    .game-character {
        position: absolute;
        bottom: 0px;
        width: 40%;
    }

    /* (A) SPEECH BOX */
    .speech {
        position: relative;
        /* (A1) FONT & COLORS */
        font-size: var(--speech-text-text-size);
        color: var(----speech-text-color);
        background: var(--speech-text-background-color);
    
        /* (A2) DIMENSIONS */
        padding: 20px;
        border-radius: 10px;
        border: 1px solid black;
    }
    
    
    /* (D) POSITION THE CALLOUT */
    #cbwrap {
        padding:30px;
        border-radius:10px
    }
    .game-text-bubble
    {
        position: relative;
        border: 1px solid black;
        background: rgba(255, 255, 255, 0.384);
        padding: 30px;
        font-size: 20px;
        border-radius: 30px;
        width: 100%;
        margin-top: 50px;
        z-index: 99999 !important;
    }

    /** Common styles for scene */
    .title-scene
    {
        font-family: 'Rockwell Nova';
        color: var(--bg-main-color) !important;
        font-size: 29px;
    }

    .description-scene
    {
        font-size: 19px;
        font-family: 'Oswald Medium';        
    }
    /* End common styles for scene */

    .vue-countdown-component .time-box {
        position: relative;
        box-sizing: border-box;
        font-weight: bold;
        height: 46px !important;
        min-width: 39px !important;
        font-size: 37px !important;
        text-align: center;
        -webkit-perspective: 60px;
        perspective: 78px;
        border-radius: 3px;
        padding: 0 10px;
        color: var(--clock-text-color) !important;
        line-height: 46px !important;
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .btn-red 
    {
        color: #fff !important;
        background-color: var(--bg-main-color) !important;
    }

    .btn-white 
    {
        color: var(--bg-main-color) !important;
        background: #fff !important;
    }

    .nav-red
    {
        color: #fff !important;
        background-color: var(--bg-main-color) !important;
    }

    .nav-white 
    {
        color: var(--bg-main-color) !important;
        background: #fff !important;
    }

    /**
        Scene styles
    */

    #scene-image
    {
        max-width: 100%;
        max-height: 100%;
    }

    #scene-image svg
    {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    .main-ripple
    {
        width: 4vw;
        height: 4vw;
        border: 4px solid rgb(0, 166, 255);
        border-radius: 100%;
        animation: rippleAnimation 1s ease-out infinite;
    }

    .ripple
    {
        width: 4vw;
        height: 4vw;
        border: 3px solid red;
        border-radius: 100%;
        animation: rippleAnimation 1s ease-out infinite;
    }

    @keyframes rippleAnimation {
        0% {
            width: 4vw;
            height: 4vw;
            border-radius: 100%;
            opacity: 1;
        }
        100% {
            width: 6vw;
            height: 6vw;
            border-radius: 50%;
            opacity: 0;
        }
    }

    .main-small-ripple {
        position: absolute;
        width: 2vw;
        height: 2vw;
        border: 2px solid rgb(0, 166, 255);
        border-radius: 100%;
        animation: smallRippleAnimation 1s ease-out infinite;
    }

    .small-ripple {
        position: absolute;
        width: 2vw;
        height: 2vw;
        border: 2px solid red;
        border-radius: 100%;
        animation: smallRippleAnimation 1s ease-out infinite;
    }

    @keyframes smallRippleAnimation {
        0% {
            width: 2vw;
            height: 2vw;
            border-radius: 100%;
            opacity: 1;
        }
        100% {
            width: 4vw;
            height: 4vw;
            border-radius: 50%;
            opacity: 0;
        }
    }
    
    /**
        main color
    */
    .main-color
    {
        color: var(--main-color);
    }

    /**
        Styles for activities
    */

    .activity-dialogue-container {
        background-color: var(--activity-dialogue-background) !important;
        color: var(--activity-dialogue-color) !important;
    }
    
    .activity_title {
        font-size: 30px;
        color: var(--bg-main-color);
    }

    /* Normal state */
    .btn-activity {
        background-color: var(--btn-activity-bg-color) !important;
        color: white !important;
        border-radius: var(--btn-activity-border-radius) !important;
    }

    /* Checked state */
    .btn-check:checked + .btn-activity {
        background-color: var(--btn-activity-bg-color-active) !important;
        color: #fff !important;
    }

    /* Active state */
    .btn-activity:active,
    .btn-activity.active,
    .btn-activity.show {
        background-color: var(--btn-activity-bg-color-checked) !important;
        color: #fff !important;
    }

    .btn-locked {
        opacity: 0.5 !important;
        pointer-events: none;
    }

    /**
    * Navigation bar
    */
    #navigationbar
    {
        margin:0px;
        padding:0px;
        width:100%;
        background-color: var(--main-color);
        height: 100px;
    }

    @media (max-width: 1500px) {
        
    }

   /* 
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
    */

    @media (min-width: 1281px) {

        *{
            /*color: red;*/
        }
    }

    /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
    */

    @media (min-width: 1025px) and (max-width: 1280px) {

        *{
            /*color: green;*/
        }
    }

    /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
    */

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

        *{
            /*color: blue;*/
        }
    }

    /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 868px to 1024px
    */

    @media (min-width: 868px) and (max-width: 1024px) {
        
        *{
            /*color: yellow;*/
        }
        

        .btn-activity
        {
            font-size: 2vw !important;
        }
        
        .speech
        {
            font-size: 2vw !important;
        }
    }

    /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
    */

    @media (min-width: 481px) and (max-width: 867px) {
        *{
            /*color: green !important;*/
        }

        
        .vue-countdown-component .time-box
        {
            font-size: 15px !important;
            height: 35px !important;
            line-height: 35px !important;
        }

        .btn-activity
        {
            font-size: 2vw !important;
        }
        
        .speech
        {
            font-size: 2vw !important;
        }
    }

    /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
    */

    @media (min-width: 481px) and (max-width: 667px) {
        *{
            /*color: green !important;*/
        }

        
        .vue-countdown-component .time-box
        {
            font-size: 15px !important;
            height: 35px !important;
            line-height: 35px !important;
        }

        .btn-activity
        {
            font-size: 2.5vw !important;
        }
        
        .speech
        {
            font-size: 2.5vw !important;
        }
    }


    /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
    */

    @media (min-width: 320px) and (max-width: 480px) {

        *{
           /*color: pink;*/
        }

        
        .vue-countdown-component .time-box
        {
            font-size: 15px !important;
            height: 35px !important;
            line-height: 35px !important;
        }

        .btn-activity
        {
            font-size: 3vw !important;
        }
        
        #cbwrap
        {
            width: 100% !important;
        }

        .speech
        {
            font-size: 3vw !important;
        }
    }
</style>
