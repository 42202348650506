<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <Checks :activity="activity"></Checks>
        <button class="position-fixed w-auto bottom-0 start-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene3'});">Volver</button>
    </div>
</template>

<script>
import Checks from './../activity/types/Checks.vue'
import utils from '../../../utils'
export default {
    components: {
        Checks
    },
    data(){
        return {
            activity:
            {
                id : "activity3_3",
                scene : "scene3",
                title : "Temas a tratar durante la consulta",
                intro_text : "María necesita adquirir diferentes conceptos sobre insulinización, por ello esta consulta de es vital importancia" ,
                question : "¿Qué contenidos deberían de tratar María y la enfermera en esta consulta?",
                options : [
                    {
                        text: 'Técnica de manejo de la insulina',
                        value: 0,
                    },
                    {
                        text: 'Técnica de manejo del glucómetro',
                        value: 1,
                    },
                    {
                        text: 'Detección y manejo de hipoglucemias',
                        value: 2,
                    },
                    {
                        text: 'Conceptos básicos de alimentación',
                        value: 3,
                    },
                    {
                        text: 'Cuidado de los pies',
                        value: 4,
                    },
                    {
                        text: 'Registro de los datos en el cuaderno de la paciente',
                        value: 5,
                    },
                    {
                        text: 'Registro de alimentación de 24 horas',
                        value: 6
                    }
                ],
                correct_answer: [
                    0, 1, 2, 3, 5
                ],
                score: 15,
                clue_text : "Hay 5 respuestas correctas",
                incorrect_msg : "La respuesta es incorrecta",
                feedback : "activity3_3_feedback"
            }
        };
    },
    updated: function(){
        this.$store.commit("SetNavBar", 'red');
    },
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity3_3');
    }
}
</script>