<template>
    <div class="w-100 animate__animated animate__fadeIn activity-dialogue-container">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <img id="activity-image" src="@/assets/esc_3_act_2-min.png" alt="esc1act1" rel="preload">
            <button class="position-fixed w-auto bottom-0 end-0 btn btn-danger rounded m-5 text-oswald" onclick="router.push({name:'scene3'});">Volver</button>
        </div>
    </div>
</template>

<script>
import utils from '../../../utils'
export default {
    name: "act_02",
    mounted: function() {
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity3_2');
        this.$store.commit("SetActivityStatus", 
            {
                scene: 'scene3', 
                act: 'activity3_2', 
                status: true
            }
        );
        
        var audio = vueapp.$store.state.sounds["CorrectSound"];
        if(audio)
        {
            audio.pause();
            audio.currentTime = 0;
            audio.play();
        }
    }
}
</script>

<style scoped>
#activity-image
{
    max-width: 100%;
    max-height: 100%;
}
</style>