<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="activity-container  w-100 h-100">
                <div class="row w-100 h-100 m-0 d-flex justify-content-center align-items-start">
                    <div class="col-md-6 feedback1-5-text">
                        <div class="m-5 p-3 text-oswald">
                            <p>Debido a que con 20 unidades en el día previo la reducción de la glucemia fue moderada sería conveniente un aumento de dosis de un 20%, hasta las 24 unidades.</p>
                            <p>No podríamos utilizar de momento una pauta bolo-basal, ya que la paciente de momento no sabe administrarse la insulina ni medir la glucemia capilar.</p>
                            <p class="text-center">Conserva estas letras para el reto final</p>
                            <h2 class="text-center feedback-big-text">O U F</h2>
                        </div>
                    </div>    
                    <div class="col-md-6 d-flex justify-content-center align-items-center">
                        <img id="feedback-image" src="@/assets/esc_2_act_4-min.png" alt="feedback-image">
                    </div> 
                </div>
            </div>
        </div>
        <button class="position-absolute btn btn-danger bottom-0 start-0 rounded m-5 text-oswald" onclick="router.push({name:'scene2'});">Volver</button>
    </div>
</template>

<script>
import utils from '../../../utils'
export default {
    name: 'Feedback2_4',
    mounted(){
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity2_4_feedback');
        this.$store.commit("SetSceneStatus", {scene: 'scene3',status: false});
    },
}
</script>

<style scoped>
#feedback-image{
    max-width: 100%;
    max-height: 100%;
}
</style>