<template>
    <div class="w-100 animate__animated animate__fadeIn">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="activity-container  w-100 h-100">
                <div class="row w-100 h-100 m-0 d-flex justify-content-center align-items-start">
                    <div class="col-md-6 feedback1-5-text">
                        <div class="p-3 text-oswald">
                            <h5 class="text-center feedback-final-sentence font-weight-bold w-100">Una vez resuelto el episodio agudo es muy probable que se pueda retirar tal y como indica el algoritmo de tratamiento de la redGDPS</h5>
                        </div>
                    </div>    
                    <div class="col-md-6 d-flex justify-content-center align-items-center">
                        <img id="feedback-image" src="@/assets/esc_5_act_4.png" alt="feedback-image">
                    </div> 
                </div>
            </div>
        </div>
        <div class="position-absolute bottom-0 w-100 d-flex justify-content-center align-items-center">
            <button class="btn btn-danger rounded m-5 text-oswald" @click="Finish">Finalizar</button>
        </div>
    </div>
</template>

<script>
import utils from '../../../utils'
export default {
    name: 'Feedback5_4',
    mounted(){
        utils.AdjustGameScreen();
        this.$store.commit("SetNavBar", 'red');
        this.$store.commit("SetLastURL", 'activity5_4_feedback');        
        this.$store.commit("SetGameOver", false);
    },
    methods:
    {
        Finish(){
            this.$store.commit("SetTimer", 0);
            this.$router.push({name:'end'});
        }
    }
}
</script>

<style scoped>
#feedback-image{
    max-width: 100%;
    max-height: 100%;
}
</style>