<template>
    <div>
        <div id="map" class="w-100 h-100 d-flex justify-content-center align-items-center position-relative">
            <img id="map-image" class="animate__animated animate__fadeIn" src="@/assets/mapa.png" @load="this.AdjustCanvas" usemap="#image-map">
            <map name="image-map">
                <area target="" alt="" title="" href="javascript:void(0)" data-style="true" @mouseover="HighlightScene(1)" @mouseout="UnhighlightScene()" @click="LoadScene(1)" coords="203,31,394,140,392,262,203,375,12,262,12,142" shape="poly">
                <area target="" alt="" title="" href="javascript:void(0)" data-style="true" @mouseover="HighlightScene(2)" @mouseout="UnhighlightScene()" @click="LoadScene(2)" coords="944,29,1135,135,1135,260,948,370,754,262,754,139" shape="poly">
                <area target="" alt="" title="" href="javascript:void(0)" data-style="true" @mouseover="HighlightScene(3)" @mouseout="UnhighlightScene()" @click="LoadScene(3)" coords="1647,31,1836,142,1836,264,1649,375,1456,266,1456,142" shape="poly">
                <area target="" alt="" title="" href="javascript:void(0)" data-style="true" @mouseover="HighlightScene(4)" @mouseout="UnhighlightScene()" @click="LoadScene(4)" coords="201,542,388,651,388,775,203,884,12,775,12,651" shape="poly">
                <area target="" alt="" title="" href="javascript:void(0)" data-style="true" @mouseover="HighlightScene(5)" @mouseout="UnhighlightScene()" @click="LoadScene(5)" coords="1647,541,1834,649,1836,769,1647,880,1460,771,1458,647" shape="poly">
                <area target="" alt="" title="" href="javascript:void(0)" data-style="false" data-bs-toggle="modal" data-bs-target="#modal-help" coords="962,409,1003,432,1004,446,1071,481,1077,492,1114,515,1116,575,1126,578,1140,585,1144,596,1181,617,1184,637,1289,704,1043,854,692,640,817,568,817,501,934,434,937,423" shape="poly">
            </map>
        </div>
        <canvas id="overlay" class="animate__animated animate__fadeIn" style="pointer-events: none; position: absolute;"></canvas>
        <!-- @/assets/lock.svg -->
        <img id="lock-svg" src="@/assets/lock.svg" @load="LockLoader" style="position: absolute; visibility: hidden;">
    </div>
</template>

<script>
import utils from '../utils'
    export default {
        name: 'Map',
        data() {
            return {
                areas: [],
                over_scene: 0,
                lock: null,
            };
        },
        methods: {
            LockLoader(evt)
            {
                this.lock = evt.target;
                this.AdjustCanvas();
            },
            LoadScene(scene_id)
            {
                if(!this.$store.state.scenes['scene'+scene_id].locked)
                    this.$router.push({name: 'scene'+scene_id});
            },
            HandleResize()
            {
                this.AdjustCanvas();
            },
            HighlightScene(scene_id) {
                this.over_scene = scene_id;
                this.AdjustCanvas();
            },
            UnhighlightScene() {
                this.over_scene = 0;
                this.AdjustCanvas();
            },
            AdjustCanvas() {
                if($('#map-image').length>0)
                {
                    let img_rect = $('#map-image')[0].getBoundingClientRect();
                    $('#overlay')
                        .css('top', img_rect.top + 'px')
                        .css('left', img_rect.left + 'px');

                    // Get the canvas element
                    var canvas = document.getElementById('overlay');
                    var ctx = canvas.getContext('2d');

                    // Set canvas dimensions to match the image
                    var img = document.getElementById('map-image');
                    canvas.width = img.clientWidth;
                    canvas.height = img.clientHeight;

                    // Define the original dimensions of the image
                    var originalWidth = img.naturalWidth;
                    var originalHeight = img.naturalHeight;

                    // Calculate scaling factors
                    var scaleX = canvas.width / originalWidth;
                    var scaleY = canvas.height / originalHeight;

                    // Draw shapes for each area
                    ctx.lineWidth = 3; // Set the line width

                    this.areas.forEach((coords, idx) => {
                        ctx.beginPath();
                        ctx.moveTo(coords[0] * scaleX, coords[1] * scaleY);
                        for (var i = 2; i < coords.length; i += 2) {
                            ctx.lineTo(coords[i] * scaleX, coords[i + 1] * scaleY);
                        }
                        ctx.closePath();

                        // Calculate center point of the shape
                        var centerX = 0;
                        var centerY = 0;
                        for (var i = 0; i < coords.length; i += 2) {
                            centerX += coords[i] * scaleX;
                            centerY += coords[i + 1] * scaleY;
                        }
                        centerX /= (coords.length / 2);
                        centerY /= (coords.length / 2);
                        
                        // Draw a dot or point in the center
                        //ctx.beginPath();
                        //ctx.arc(centerX, centerY, 3, 0, 2 * Math.PI);
                        //ctx.fillStyle = 'red'; // Change color if needed
                        //ctx.fill();

                        // Fill and stroke the shape
                        if (this.$store.state.scenes['scene' + (idx + 1)].locked) {
                            if ((idx + 1) == this.over_scene) {
                                ctx.fillStyle = 'rgba(0, 0, 0, 0.4)';
                            } else {
                                ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
                            }
                            ctx.fill();
                            ctx.strokeStyle = 'rgba(0, 0, 0, 0.7)';
                            ctx.stroke();

                            if (this.lock) {
                                // Draw lock image at the center point
                                var lockWidth = this.lock.width *0.05;
                                var lockHeight = this.lock.height *0.05;
                                var lockX = centerX - (lockWidth / 2);
                                var lockY = centerY - (lockHeight / 2);
                                ctx.drawImage(this.lock, lockX, lockY, lockWidth, lockHeight);
                            }
                        }
                    });
                }
            }
        },
        mounted()
        {
            utils.AdjustGameScreen();
            this.$store.commit("SetNavBar", 'white');
            this.$store.commit("SetLastURL", 'map');

            let map_areas = $('area').toArray();
            map_areas.forEach((a) => {
                let cords = $(a).attr('coords').split(',');
                if($(a).attr('data-style') == "true")
                    this.areas.push(cords);
            });

            window.$.imageMapResize();
          
            this.AdjustCanvas();
            window.addEventListener("resize", this.HandleResize);
        },
    }
</script>

<style scoped>
    #map
    {
        background-color: #47535a !important;
        /*background-image: url(@/assets/mapa.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;*/
    }

    #map-image
    {
        max-width: 100%;
        max-height: 100%;
    }
</style>
